import React, { useState } from 'react';
import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { useProfiles } from 'app/hooks/backend';
import UserAccountLayout from './layout';
import { LButton } from 'app/components/widgets/buttons';
import { ArbitrationsTable } from 'app/components/tables/arbitrations';

export default function ArbitrationsView() {
  const { profile } = useProfiles();
  const [filters, setFilters] = useState<any>({});

  const handleOnClick = () => {
    if (filters.hasOwnProperty('created_by')) {
      delete filters['created_by'];
      setFilters({ ...filters });
    } else {
      setFilters({ ...filters, created_by: profile.username });
    }
  };

  return (
    <UserAccountLayout title="Arbitrations">
      <HStack spacing={2} alignItems="center" mb="4">
        <Text fontSize={['lg', 'xl']} fontWeight="bold" color="black">
          All Arbitrations
        </Text>
        <Tooltip label="View all Payments">
          <QuestionIcon />
        </Tooltip>
      </HStack>
      <Box mb="4" fontSize="xs">
        <Button
          dark={filters.hasOwnProperty('created_by')}
          as={LButton}
          onClick={handleOnClick}
          btnSize="xs">
          RAISED BY YOU
        </Button>
      </Box>
      {/* TRANSACTION TABLE */}
      <ArbitrationsTable status="" filters={filters} />
    </UserAccountLayout>
  );
}
