import { v4 as uuidv4 } from 'uuid';

export const createUrlParams = (obj: any) => {
  const p = [];
  for (const key in obj) {
    if (obj[key]) p.push(key + '=' + encodeURIComponent(obj[key]));
  }
  return p.join('&');
};

export const getErrorMessage = (error: any) => {
  if (!error?.response?.data) return error?.detail || getAPIError(error) || error.message;
  const err = error.response.data;
  return err?.detail || getAPIError(err);
};

const getAPIError = (errObj: any) => {
  const firstKey = Object.keys(errObj)[0];
  const error = errObj[firstKey][0];
  return `${firstKey}: ${error}`;
};

export const getPageRanges = (page: number = 1, limit: number = 10) => {
  const start = page === 1 ? 0 : limit * (page - 1);
  const end = start + (limit - 1);
  return { start, end };
};

export const generateUUID = (prefix?: string) => (prefix ? `${prefix}.${uuidv4()}` : uuidv4());
