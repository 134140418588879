import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import * as React from 'react';

import { ContractStatus } from '../controls/badges';
import { LButton } from './buttons';
import { Card, CardContent, CardHeader, Property } from './cards';
import { UserProfile } from 'domain/repositories';
import useAuthStore from 'app/state/store/auth';
import { handleError, handleSuccess } from 'app/hooks/backend';
import { useHistory } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';

type Props = {
  user: UserProfile | null;
};
export const UserDetails = ({ user }: Props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { suspendUser } = useAuthStore();
  const history = useHistory();

  const handleSuspendUser = async (username?: string) => {
    const response = await suspendUser(username);
    if (response.status === 200) {
      handleSuccess(response.message);
      history.push(AppRoutes.USERS);
    } else {
      handleError(response.message);
    }
  };

  return (
    <Card>
      <CardHeader
        title={<ContractStatus status={user?.is_active ? 'Active User' : 'Suspended User'} />}>
        <Button
          as={LButton}
          dark
          btnSize="sm"
          _focus={{ boxShadow: 'none', outline: 'none' }}
          onClick={onOpen}
          size="sm"
          colorScheme="red">
          {user?.is_active ? 'Suspend User' : 'Un Suspend User'}
        </Button>
      </CardHeader>

      {/* REASON MODEL */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Suspend User</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to delete this user</Text>
          </ModalBody>

          <ModalFooter>
            <Button as={LButton} onClick={onClose}>
              Close
            </Button>
            <Button as={LButton} dark onClick={() => handleSuspendUser(user?.username)}>
              Suspend
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <CardContent>
        <>
          <Property label="UUID" value={user?.uuid} />
          <Property label="First Name" value={user?.first_name} />
          <Property label="Last Name" value={user?.last_name} />
          <Property label="Username" value={user?.username} />
          <Property label="Email Address" value={user?.email} />
        </>
      </CardContent>
    </Card>
  );
};
