import React from 'react';
import { BrowserRouter, Router } from 'react-router-dom';

import App from './App';
import history from 'app/services/history';

const Root = () => {
  return (
    <Router history={history}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Router>
  );
};

export default Root;
