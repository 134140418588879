import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useDisclosure,
  Text,
  useBreakpointValue,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { useResource } from 'app/hooks/backend/resource';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import { LBadge } from '../controls/badges';
import Pagination from '../controls/pagination';

import { Copier, DateInfo } from '../widgets/misc';
import EditableText from '../forms/fields/editable';
import { DeleteIcon } from '@chakra-ui/icons';

export const ChargesTable = ({ filters }: any) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { getResources, loading, deleteResource } = useResource('charges');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const cancelRef = React.useRef<any>();

  const { isLoading, data, refetch } = useQuery(
    ['charges', { page, filters }],
    () =>
      getResources({
        page,
        filters,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const openDialog = (item: any) => {
    setSelectedRecord(item);
    onOpen();
  };

  const deleteCharge = () => {
    deleteResource(selectedRecord.uuid);
    refetch();
    onClose();
  };

  if (isLoading || loading) return <Loading />;

  const list = data?.results;
  const total = data?.count;

  return (
    <>
      <Box className="lg:hidden flex flex-col space-y-2 text-sm">
        {list?.map((item: any) => (
          <Box
            key={`mobile-${item.id}`}
            p={2}
            borderLeftColor="sushi"
            borderLeftWidth="4px"
            rounded="md"
            bg="sushi.100">
            <Box className="flex justify-between border-b-0">
              <div className="flex flex-col max-w-1/2">
                <span className="font-black">Amount: {Number(item.amount).toLocaleString()}</span>
                <i className="font-semibold text-gray-600">Type: {item.charge_type}</i>
              </div>
              <div className="flex flex-col">
                <span className="font-black text-right">
                  Min: {Number(item.min_value).toLocaleString()}
                </span>
                <span className="font-black text-right">
                  Max: {Number(item.max_value).toLocaleString()}
                </span>
              </div>
            </Box>
            <Text mt="1" fontSize="0.75rem">
              {item.narration}
            </Text>
          </Box>
        ))}
        <span className="mb-4" />
      </Box>
      <Table hidden={isMobile} variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>Min Amount</Th>
            <Th>Max Amount</Th>
            <Th>Type</Th>
            <Th>Charge</Th>
            <Th>Created On</Th>
          </Tr>
        </Thead>
        <Tbody>
          {list?.map((item: any) => (
            <Tr key={item.id}>
              <Td>
                <Copier>{item.id}</Copier>
              </Td>
              <Td>
                <EditableText
                  attribute="min_value"
                  value={Number(item.min_value).toLocaleString()}
                  endpoint="charges"
                  id={item.uuid}
                />
              </Td>
              <Td>
                <EditableText
                  attribute="max_value"
                  value={Number(item.max_value).toLocaleString()}
                  endpoint="charges"
                  id={item.uuid}
                />
              </Td>
              <Td>
                <LBadge>{item.charge_type}</LBadge>
              </Td>
              <Td>
                <EditableText
                  attribute="amount"
                  value={Number(item.amount).toLocaleString()}
                  endpoint="charges"
                  id={item.uuid}
                />
              </Td>
              <Td>
                <DateInfo date={item.date_created} />
              </Td>
              <Td>
                <IconButton size="xs" aria-label="Delete" onClick={() => openDialog(item)}>
                  <DeleteIcon />
                </IconButton>
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination page={page} total={total} limit={10} setPage={setPage} />
      <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="md" fontWeight="bold">
              Delete Customer
            </AlertDialogHeader>

            <AlertDialogBody fontSize="0.9em">Are you sure? You can't undo this action afterwards.</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose} size="sm">
                Cancel
              </Button>
              <Button colorScheme="red" onClick={deleteCharge} ml={3} size="sm">
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};
