import {
  ContractsApi,
  PaymentsApi,
  RemoteAuthentication,
  ReportApi,
  ResourcesApi,
  UserApi,
} from 'data/api';
import {
  AuthInteractor,
  ContractsInteractor,
  PaymentsInteractor,
  ReportInteractor,
  ResourcesInteractor,
  UsersInteractor,
} from 'domain/usecases';
import { makeApiUrl, makeAxiosHttpClient } from './http';

import { makeAuthorizedHttpClient } from './decorators';
import { ArbitrationApi } from 'data/api/arbitration';
import { ArbitrationsInteractor } from 'domain/usecases/arbitrations';

export const makeRemoteAuthentication = (): AuthInteractor =>
  new AuthInteractor(new RemoteAuthentication(makeApiUrl(''), makeAxiosHttpClient()));

export const makeUserApi = (): UsersInteractor =>
  new UsersInteractor(new UserApi(makeApiUrl('/users'), makeAuthorizedHttpClient()));

export const makeReportsApi = (): ReportInteractor =>
  new ReportInteractor(new ReportApi(makeApiUrl('/reports'), makeAuthorizedHttpClient()));

export const makeContractApi = (): ContractsInteractor =>
  new ContractsInteractor(new ContractsApi(makeApiUrl('/contracts'), makeAuthorizedHttpClient()));

export const makePaymentApi = (): PaymentsInteractor =>
  new PaymentsInteractor(new PaymentsApi(makeApiUrl('/transactions'), makeAuthorizedHttpClient()));

export const makeArbitrationApi = (): ArbitrationsInteractor =>
  new ArbitrationsInteractor(
    new ArbitrationApi(makeApiUrl('/arbitrations'), makeAuthorizedHttpClient())
  );

export const makeResourceApi = (resource: string): ResourcesInteractor =>
  new ResourcesInteractor(new ResourcesApi(makeApiUrl(`/${resource}`), makeAuthorizedHttpClient()));
