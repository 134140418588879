import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  TableCaption,
  Skeleton,
  useBreakpointValue,
  chakra,
  Tooltip,
  HStack,
  Icon,
  Text,
  VStack,
  Center,
} from '@chakra-ui/react';
import { useContracts } from 'app/hooks/backend/contracts';
import { useStore } from 'app/state/store';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { getStatusColor, ContractStatus, LBadge } from '../controls/badges';
import { ContractDetail } from '../widgets/contract-detail';
import Pagination from '../controls/pagination';
import { ArrowBackIcon, ArrowForwardIcon, RepeatClockIcon } from '@chakra-ui/icons';
import classnames from 'classnames';
import { Feature } from '../controls';
import { DateInfo, LimboUser } from '../widgets/misc';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';
import { useProfiles } from 'app/hooks/backend';
import useTransactionStore from 'app/state/store/transactions';
import { PaymentCreateParams } from 'domain/repositories/payment';
import useAuthStore from 'app/state/store/auth';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contract: PaymentCreateParams;
};

const ViewContractModal = ({ isOpen, onClose, contract }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="4xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Transaction Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <ContractDetail contract={contract} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const ContractsTable = ({ filters }: any) => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedContract, setSelectedContract] = useState<PaymentCreateParams | any>(null);
  const { profile } = useProfiles();

  const { transactions, loading, getPayments } = useTransactionStore();
  const { fetchAllUsers, users } = useAuthStore();

  const openViewContractModel = (contract: PaymentCreateParams) => {
    setSelectedContract(contract);
    onOpen();
  };

  useEffect(() => {
    const getData = async () => {
      await getPayments();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getUsers = async () => {
      await fetchAllUsers();
    };
    if (users.length === 0) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let filteredTransactions = transactions;

  if (filters.hasOwnProperty('created_by')) {
    filteredTransactions = transactions.filter((item: PaymentCreateParams) => {
      return item.created_by === filters.created_by;
    });
  }

  // Calculate pagination
  const limit = 10; // Number of transactions per page
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedTransactions = filteredTransactions.slice(startIndex, endIndex);

  // if (loading) return <Loading />;
  const total = filteredTransactions?.length || 0;
  if (total === 0) return <Center>{'No transactions for now. Make some transactions 🙂'}</Center>;
  return (
    <>
      <Feature on={isMobile}>
        <Skeleton isLoaded={!loading}>
          <Box className="lg:hidden flex flex-col space-y-2 text-sm">
            {paginatedTransactions?.map((item: PaymentCreateParams) => (
              <Box
                key={`mobile-${item.uuid}`}
                className={`rounded border-l-4 border-${getStatusColor(
                  item.status
                )}-500 bg-${getStatusColor(item.status)}-50`}
                onClick={() => openViewContractModel(item)}>
                <Box className="flex justify-between border-b-0 p-2">
                  <div className="flex flex-col max-w-1/2">
                    <span className="font-black">
                      {dayjs(item.date_created).format('DD MMM, YYYY')}
                    </span>
                    <i className="font-black text-gray-400">{item.phone}</i>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-black text-right">
                      {Number(item?.amount).toLocaleString()}
                    </span>
                    <i className="font-black text-gray-400 text-right flex flex-end">
                      {item.charges / 100}%
                    </i>
                  </div>
                </Box>
              </Box>
            ))}

            <span className="mb-4" />
          </Box>
        </Skeleton>
      </Feature>

      <Feature on={!isMobile}>
        <Box overflowX="auto">
          <Skeleton isLoaded={!loading}>
            <Table variant="simple" mb="6" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Feature
                    permissions={{
                      allowed: [
                        { obj: location, attribute: 'pathname', value: AppRoutes.USER_PAYMENTS },
                      ],
                      rejected: [],
                    }}>
                    <Th>Towards</Th>
                  </Feature>
                  <Feature
                    permissions={{
                      allowed: [
                        { obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS },
                      ],
                      rejected: [],
                    }}>
                    <Th>Payer</Th>
                    <Th>Receiver</Th>
                  </Feature>
                  <Th>Amount</Th>
                  <Th>Status</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedTransactions?.map((item: PaymentCreateParams) => (
                  <Tr key={item.uuid}>
                    <Td>
                      <Tooltip
                        label={
                          item.sender === profile?.username && item.kind === 'transfer'
                            ? 'Paying'
                            : 'Requesting'
                        }>
                        {item.sender === profile?.username ? (
                          <Icon
                            transform={'rotate(315deg)'}
                            color="white"
                            className={classnames('rounded-full bg-limbo-error')}
                            as={ArrowForwardIcon}
                          />
                        ) : (
                          <Icon
                            transform={'rotate(315deg)'}
                            color="white"
                            className={classnames('rounded-full bg-limbo-contrast')}
                            as={ArrowBackIcon}
                          />
                        )}
                      </Tooltip>
                    </Td>
                    <Feature
                      permissions={{
                        allowed: [
                          { obj: location, attribute: 'pathname', value: AppRoutes.USER_PAYMENTS },
                        ],
                        rejected: [],
                      }}>
                      <Td>
                        {item.sender === profile.username ? (
                          <LimboUser username={item.receiver} />
                        ) : (
                          <LimboUser username={item.sender} />
                        )}
                      </Td>
                    </Feature>
                    <Feature
                      permissions={{
                        allowed: [
                          { obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS },
                        ],
                        rejected: [],
                      }}>
                      <Td>{item.sender}</Td>
                      <Td>{item.receiver}</Td>
                    </Feature>
                    <Td>{Number(item.amount).toLocaleString()}</Td>
                    <Td>
                      <HStack spacing="1">
                        <Feature>
                          <Tooltip label={<ContractEvents events={item.meta?.events} />}>
                            <RepeatClockIcon boxSize={3} />
                          </Tooltip>
                        </Feature>
                        <ContractStatus status={item.status} />
                      </HStack>
                    </Td>

                    <Td>
                      <Text width="5rem">
                        <DateInfo date={item.date_created} />
                      </Text>
                    </Td>
                    <Td>
                      <chakra.button
                        px="2"
                        py="0.5"
                        fontSize="xs"
                        bg="limbo.primary"
                        color="white"
                        rounded="full"
                        _focus={{ boxShadow: 'none', outline: 'none' }}
                        onClick={() => openViewContractModel(item)}
                        _hover={{ bg: 'limbo.contrast' }}>
                        View
                      </chakra.button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Skeleton>
        </Box>
      </Feature>

      <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      <ViewContractModal isOpen={isOpen} onClose={onClose} contract={selectedContract} />
    </>
  );
};

const ContractEvents = ({ events }: any) => (
  <VStack>
    {events?.map((event: any, idx: number) => (
      <Box key={idx} p="1">
        <Text fontSize="xs" fontWeight="bold">
          {event.detail}
        </Text>
        <HStack spacing="4">
          <Text fontSize="xs">{dayjs(event?.timestamp).format('YYYY-MM-DD')}</Text>
          <Text fontSize="xs">{dayjs(event?.timestamp).fromNow()}</Text>
        </HStack>
      </Box>
    ))}
  </VStack>
);

export const ContractSchedule = ({ isMobile }: any) => {
  const { getContractSchedule } = useContracts();
  const latestContract = useStore(s => s.latestContract);

  const { isLoading, data: schedule } = useQuery(
    ['contracts', { latestContract }],
    () => getContractSchedule(latestContract.uuid),
    {
      refetchOnWindowFocus: false,
    }
  );

  return (
    <Skeleton isLoaded={!isLoading}>
      <Box className="lg:hidden flex flex-col space-y-2 text-sm">
        <Box className="flex justify-between border-b py-2">
          <span className="text-gray-500 font-black">Jun 21</span>
          <div className="flex flex-col">
            <span className="font-black text-right">10,000</span>
            <i className="font-black text-gray-400">Bal. 90,000</i>
          </div>
        </Box>
        <Box className="flex justify-between border-b py-2">
          <span className="text-gray-500 font-black">Jun 21</span>
          <div className="flex flex-col">
            <span className="font-black text-right">10,000</span>
            <i className="font-black text-gray-400">Bal. 90,000</i>
          </div>
        </Box>
        <Box className="flex justify-between border-b py-2">
          <span className="text-gray-500 font-black">Jun 21</span>
          <div className="flex flex-col">
            <span className="font-black text-right">10,000</span>
            <i className="font-black text-gray-400">Bal. 90,000</i>
          </div>
        </Box>
        <span className="mb-4" />
      </Box>
      <Table hidden={isMobile} variant="simple">
        <TableCaption>
          This is a recommendation on how to clear the contract quickly in a managable way
        </TableCaption>
        <Thead>
          <Tr>
            <Th>Due Date</Th>
            <Th isNumeric>Amount</Th>
            <Th isNumeric>Balance</Th>
          </Tr>
        </Thead>
        <Tbody>
          {schedule?.map((item: any) => (
            <Tr>
              <Td>{item.due_date}</Td>
              <Td isNumeric>{item.amount.toLocaleString()}</Td>
              <Td isNumeric>{item.balance.toLocaleString()}</Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Skeleton>
  );
};
