import create from 'zustand';
import { getErrorMessage } from 'app/utils';
import { handleError, handleSuccess } from 'app/hooks/backend';
import { makeUserApi } from 'factory';
import {
  ChangePassword,
  PasswordReset,
  ResetPassword,
  UserProfile,
  VerifyPasswordReset,
} from 'domain/repositories';
import api from 'app/services/api';

type AuthStore = {
  loading: boolean;
  users: UserProfile[];
  resetToken: string;
  otp: number | string;
  usernameForReset: string;
  getUserProfile: (username: string) => Promise<UserProfile | null>;
  resetPassword: (params: ResetPassword) => Promise<any>;
  fetchAllUsers: () => Promise<void>;
  getSingleUser: (username: string) => UserProfile | null;
  changePassword: (params: ChangePassword) => Promise<any>;
  suspendUser: (username?: string) => Promise<any>;
  verifyPasswordReset: (params: VerifyPasswordReset) => Promise<any>;
  passwordReset: (params: PasswordReset) => Promise<any>;
};

const userApi = makeUserApi();

const useAuthStore = create<AuthStore>((set, get) => ({
  loading: false,
  users: [],
  resetToken: '',
  otp: '',
  usernameForReset: '',
  // getting user profile
  getUserProfile: async (username: string): Promise<UserProfile | null> => {
    try {
      set({ loading: true });
      const { data } = await userApi.getUserProfile(username);
      set({ loading: false });
      return data;
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      handleError(errorMessage);
      console.error('Fetch user error:', error);
      set({ loading: false });
      return null;
    }
  },
  //getting all Users
  fetchAllUsers: async (): Promise<void> => {
    try {
      set({ loading: true });
      const { data } = await userApi.getAllUsers();
      console.log('the users are: ', data);
      set({ loading: false, users: data });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      handleError(errorMessage);
      console.error('Fetch user error:', error);
      set({ loading: false });
    }
  },
  // get a single user
  getSingleUser: (username: string): UserProfile | null => {
    const user = get().users.find(user => user.username === username);
    return user || null;
  },
  //changing password
  changePassword: async (values: ChangePassword) => {
    try {
      set({ loading: true });
      const { data } = await userApi.changePassword(values);
      console.log('the password change data is', data);
      set({ loading: false });
      return data;
    } catch (error) {
      console.log('the error in changin password is', error);
      return null;
    }
  },

  //suspend user instead of deleting
  suspendUser: async (username?: string) => {
    try {
      set({ loading: true });
      const { data } = await userApi.suspendUser(username);
      console.log('the data in suspend user is', data);
      set({ loading: false });
      return data;
    } catch (error) {
      console.log('the error in suspending user is', error);
      return null;
    }
  },
  //resetPassword
  resetPassword: async (params: ResetPassword): Promise<any> => {
    console.log('the code data username is', params);
    try {
      set({ loading: true });
      const { data } = await api.post('/start-password-reset', params);
      console.log('reset password data is', data);
      set({ loading: false, otp: data.otp, usernameForReset: params.username });
      return data;
    } catch (error) {
      handleError(error);
      console.log('Fetch user error:', error);
      set({ loading: false });
      return null;
    }
  },

  //verify password reset
  verifyPasswordReset: async (params: VerifyPasswordReset): Promise<any> => {
    console.log('the code data username is', params);
    try {
      set({ loading: true });
      const { data } = await api.post('/verify-password-reset', params);
      console.log('verify password data is', data);
      set({ loading: false, resetToken: data['reset-token'] });
      return data;
    } catch (error) {
      handleError(error);
      console.log('Fetch user error:', error);
      set({ loading: false });
      return null;
    }
  },
  //final password reset
  passwordReset: async (params: PasswordReset): Promise<any> => {
    set({ loading: true });
    try {
      const { data } = await api.post('/reset-password', params);
      set({ loading: false });
      return data;
    } catch (error) {
      console.log('the error in password reset is', error);
      set({ loading: false });
      return null;
    }
  },
}));

export default useAuthStore;
