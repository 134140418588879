import React from 'react';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  Text,
} from '@chakra-ui/react';

import { useSession } from 'app/hooks/backend/session';
import { useParams } from 'react-router-dom';
import { AccountStatementTable } from 'app/components/tables/statement';

const AccountStatement = () => {
  const { account_id } = useParams<any>();
  const { user } = useSession();
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Account Statement
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <AccountStatementTable
              isMobile={isMobile}
              accountId={account_id}
              filters={{ customer_id: user?.uuid, per_page: 10 }}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default AccountStatement;
