import Page from 'app/components/page';
import PublicLayout from '../public/layout';
import React from 'react';
import { Box, Grid, GridItem, Text } from '@chakra-ui/react';
import { UserAccountMenu } from 'app/layouts/sidebar';
import { Feature } from 'app/components/controls';
import { VerifyForm } from 'app/components/forms';
import { useProfiles } from 'app/hooks/backend';


export default function UserAccountLayout({ title, children, width = 'full' }: any) {
  const {  profile } = useProfiles();

  return (
    <PublicLayout title={title}>
      <Page>
        <Feature on={!profile.verified}>
          <Box
            bg="white"
            mx="auto"
            px="24"
            py="4"
            my="24"
            width="fit-content"
            border="1px solid gray.200"
            borderRadius="24px">
            <Text fontSize="xl" fontWeight="black" mb="8">
              Verify your phone number
            </Text>
            <VerifyForm />
          </Box>
        </Feature>
        <Feature on={profile.verified}>
          <Grid
            w={['100%']}
            templateColumns="repeat(5, 1fr)"
            gap={6}
            mx="auto"
            my={['2', '10']}
            alignItems="flex-start">
            <GridItem colSpan={[5, 1]}>
              <UserAccountMenu />
            </GridItem>
            <GridItem colSpan={[5, 4]}>
              <Box
                w={width}
                borderRadius="24px"
                className="bg-white px-8 py-6 border border-gray-200">
                {children}
              </Box>
            </GridItem>
          </Grid>
        </Feature>
      </Page>
    </PublicLayout>
  );
}
