import * as React from 'react';
// context providers
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { RouterProvider } from './router';
// utils
import { mode } from '@chakra-ui/theme-tools';
import brand from './brand';

const config = {
    useSystemColorMode: false,
}
const darkMode = {
    DARK: '#404447',
    DARKER: '#141214',
    DARKEST: '#121212'
}
const styles = {
    global: (props: any) => ({
        body: {
            color: mode('gray.800', 'whiteAlpha.900')(props),
            bg: mode('white', darkMode.DARKER)(props),
        },
    }),
};

const theme = extendTheme({
    ...brand,
    config,
    styles
});

const queryClient = new QueryClient();

const AppProviders = ({ children }: any) =>
    <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={theme}>
            <RouterProvider>
                {children}
            </RouterProvider>
        </ChakraProvider>
    </QueryClientProvider>;

export default AppProviders;
