import { IconButton, useDisclosure } from '@chakra-ui/react';
import React from 'react';
import { FaCode } from 'react-icons/fa';
import { Feature } from '../controls';
import { Card, CardContent, Property } from './cards';
import { LModal } from './modal';

export const BuildDetails = () => {
  const build = process.env;

  return (
    <Card>
      <CardContent>
        <>
          <Property label="Environment" value={build.NODE_ENV} />
          <Property label="Backend" value={build.REACT_APP_API_URL} />
          <Property label="Context" value={build.CONTEXT} />
          <Property label="Branch" value={build.BRANCH} />
          <Property label="Commit" value={build.COMMIT_REF} />
        </>
      </CardContent>
    </Card>
  );
};

export const BuildDetailsWidget = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <Feature
      permissions={{
        allowed: [{ attribute: 'is_superuser', value: true }],
      }}>
      <IconButton
        colorScheme="wisteria"
        size="xs"
        _focus={{ boxShadow: 'none', outline: 'none' }}
        icon={<FaCode />}
        aria-label="info"
        onClick={onOpen}
      />
      <LModal title="Build Details" isOpen={isOpen} onClose={onClose}>
        <BuildDetails />
      </LModal>
    </Feature>
  );
};
