import { useEffect } from 'react';
import { createStandaloneToast } from '@chakra-ui/react';

export const useFormErrors = (errors: any) => {
  const toast = createStandaloneToast();
  useEffect(() => {
    if (errors && Object.keys(errors).length > 0) {
      const leadingErrorKey = Object.keys(errors)[0];
      const leadingError = errors[leadingErrorKey];

      const msg = leadingError?.message;
      const errMsg = msg
        ? msg
        : `The ${leadingErrorKey} field is ${leadingError.type}. ${leadingError.message}`;

      toast({
        title: 'Form Error',
        description: errMsg,
        status: 'error',
        duration: 9000,
        isClosable: true,
        position: 'top',
      });
    }
  }, [errors, toast]);
};
