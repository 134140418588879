import React from 'react';
import { chakra } from '@chakra-ui/react';

interface LButtonProps {
  children: React.ReactNode;
  dark?: boolean;
  btnSize?: number | string;
  onClick?: (event: any) => void;
  disabled?: boolean;
}

type LimboButtonProps = LButtonProps;

export const getSize = (sizeStr: any = 'md') => {
  const sizes: any = {
    xs: { px: '2', py: '0.5', fontSize: 'xs' },
    sm: { px: '4', py: '1', fontSize: 'sm' },
    md: { px: '8', py: '2' },
    lg: { px: '10', py: '4' },
  };
  return sizes[sizeStr];
};

export const LButton = ({ children, dark, btnSize, onClick, disabled }: LimboButtonProps) => {
  const getColors = (isDark: boolean | undefined) => {
    if (isDark) {
      return { bg: 'black', color: 'white' };
    } else {
      return { bg: 'white', color: 'black', borderColor: 'black' };
    }
  };
  const palette = getColors(dark);

  const size = getSize(btnSize);

  return (
    <chakra.button
      onClick={onClick}
      d="flex"
      alignItems="center"
      justifyContent="center"
      borderRadius="full"
      border="1px"
      disabled={disabled}
      borderColor={dark ? 'black' : 'white'}
      {...size}
      {...palette}
      _focus={{ boxShadow: 'none', outline: 'none' }}
      _disabled={{ opacity: 0.5 }}
      _hover={{ bg: palette.color, color: palette.bg }}>
      {children}
    </chakra.button>
  );
};
