import { createStandaloneToast } from "@chakra-ui/react";

export * from "./auth";
export * from "./profile";

const toast = createStandaloneToast();

export const handleError = (error: any, title: string = 'Error') => {
    if (error) {
        const errMsg: any = error?.message || error;
        toast({
            title: title,
            description: errMsg,
            status: 'error',
            duration: 9000,
            isClosable: true,
            position: 'top',
        });
    }
};

export const handleSuccess = (message: string, title: string = 'Success') => {
    if (message) {
        toast({
            title: title,
            description: message,
            status: 'success',
            duration: 9000,
            isClosable: true,
            position: 'top',
        });
    }
};


export const notify = (status: "error" | "success" | "warning", data: any, title?: string, duration: number = 5000) => {
    let msg = data;

    if (status === "error") {
      msg = data?.message || data;
    }

    toast({
      title: title,
      description: msg,
      status,
      duration,
      isClosable: true,
      position: 'top',
    });
  };
