const brand = {
  colors: {
    // http://sitehero.nl/uicolors/
    limbo: {
      light: '#657680',
      error: '#5c1010',
      primary: '#1d2225',
      secondary: '#16262E',
      contrast: '#034748',
    },
    plum: {
      '50': '#fef5fd',
      '100': '#fcebfa',
      '200': '#f9d5f5',
      '300': '#f3b4e9',
      '400': '#ea88d8',
      '500': '#dc59c3',
      '600': '#bf3aa4',
      '700': '#9e2d84',
      '800': '#7c2567',
      '900': '#6b2458',
    },
    wisteria: {
      '50': '#fbf8fc',
      '100': '#f6eef9',
      '200': '#f0e0f4',
      '300': '#e3c8ea',
      '400': '#d1a4dc',
      '500': '#bd81cb',
      '600': '#b070be',
      '700': '#9350a0',
      '800': '#7a4584',
      '900': '#63396a',
    },
    sushi: {
      '50': '#f3f9ec',
      '100': '#e5f2d5',
      '200': '#cde6b0',
      '300': '#acd581',
      '400': '#8ec259',
      '500': '#7ab641',
      '600': '#56842c',
      '700': '#426625',
      '800': '#375222',
      '900': '#314621',
    },
    'tia-maria': {
      '50': '#fff7ed',
      '100': '#ffeed5',
      '200': '#fdd8ab',
      '300': '#fcbc75',
      '400': '#fa943d',
      '500': '#f87617',
      '600': '#e95b0d',
      '700': '#bf430d',
      '800': '#993613',
      '900': '#7b2e13',
    },
    eucalyptus: {
      '50': '#effaf3',
      '100': '#d8f3e0',
      '200': '#b4e6c6',
      '300': '#82d3a4',
      '400': '#4fb87f',
      '500': '#2c9d62',
      '600': '#208955',
      '700': '#176541',
      '800': '#155034',
      '900': '#12422c',
    },
    keppel: {
      '50': '#f2fbf9',
      '100': '#d1f6ed',
      '200': '#a4ebdc',
      '300': '#6edac7',
      '400': '#3cb7a5',
      '500': '#27a594',
      '600': '#1d8479',
      '700': '#1b6a62',
      '800': '#1a5550',
      '900': '#1a4743',
    },
    jagger: {
      '50': '#f8f6fe',
      '100': '#f2edfd',
      '200': '#e6dbf7',
      '300': '#d0c0ec',
      '400': '#9e81cb',
      '500': '#70509f',
      '600': '#51357b',
      '700': '#3d2167',
      '800': '#2b104f',
      '900': '#1d0138',
    },
    'swamp-green': {
      '50': '#f8fbf8',
      '100': '#f3f6f3',
      '200': '#e6ebe0',
      '300': '#d4ddca',
      '400': '#a1b38c',
      '500': '#73895b',
      '600': '#4f6342',
      '700': '#3e4f31',
      '800': '#2b301d',
      '900': '#1a2310',
    },
    contessa: {
      '50': '#fcf4f5',
      '100': '#f9eaec',
      '200': '#f2d9dc',
      '300': '#e8b9c0',
      '400': '#da929e',
      '500': '#c96c7d',
      '600': '#b24c63',
      '700': '#953b52',
      '800': '#7d3449',
      '900': '#6c2f43',
    },
    'eastern-blue': {
      '50': '#effafc',
      '100': '#d6f0f7',
      '200': '#b3e1ee',
      '300': '#7ecbe2',
      '400': '#42abce',
      '500': '#268eb2',
      '600': '#237497',
      '700': '#225e7c',
      '800': '#244f66',
      '900': '#224357',
    },
    chenin: {
      '50': '#fcfbee',
      '100': '#f4f3d1',
      '200': '#e9e79e',
      '300': '#ddd669',
      '400': '#d6c749',
      '500': '#ccae34',
      '600': '#b48c2b',
      '700': '#966a27',
      '800': '#7b5525',
      '900': '#664621',
    },
    'apple-blossom': {
      '50': '#fcf4f4',
      '100': '#f9ebea',
      '200': '#f3d9d8',
      '300': '#e9bab8',
      '400': '#dc9190',
      '500': '#cc6769',
      '600': '#b54950',
      '700': '#a73e47',
      '800': '#80313a',
      '900': '#6e2d37',
    },
    bouquet: {
      '50': '#fafafa',
      '100': '#f7f2f7',
      '200': '#eddeec',
      '300': '#e0ccdf',
      '400': '#b583b3',
      '500': '#8c5f8a',
      '600': '#6d406b',
      '700': '#543153',
      '800': '#30212f',
      '900': '#211220',
    },
    mischka: {
      '50': '#fafafa',
      '100': '#f4f3f6',
      '200': '#e3e1ea',
      '300': '#d6d3de',
      '400': '#9f98b3',
      '500': '#6f6883',
      '600': '#504964',
      '700': '#3e384d',
      '800': '#26242d',
      '900': '#17151e',
    },
    'antique-brass': {
      '50': '#fdfef5',
      '100': '#f8f9f0',
      '200': '#f2e3d9',
      '300': '#e8d2bf',
      '400': '#c99c74',
      '500': '#a37141',
      '600': '#775c2e',
      '700': '#604520',
      '800': '#3b1d12',
      '900': '#2e1805',
    },
    'brandy-rose': {
      '50': '#fcfbf7',
      '100': '#f7f6f2',
      '200': '#eededd',
      '300': '#e1c9c6',
      '400': '#b47d76',
      '500': '#925b52',
      '600': '#6b473a',
      '700': '#56332a',
      '800': '#34191c',
      '900': '#270f0c',
    },
    'blue-zodiac': {
      '50': '#f6fafe',
      '100': '#edf5fd',
      '200': '#dae6f8',
      '300': '#bfd3ed',
      '400': '#809fcc',
      '500': '#4e6ea1',
      '600': '#34517c',
      '700': '#203d68',
      '800': '#0e244b',
      '900': '#001039',
    },
    gimblet: {
      '50': '#f9f9f1',
      '100': '#ebecd9',
      '200': '#d5d8af',
      '300': '#c1c386',
      '400': '#b7b66d',
      '500': '#a9a157',
      '600': '#95864a',
      '700': '#7d6a40',
      '800': '#675639',
      '900': '#564831',
    },
    indigo: {
      100: '#ebf4ff',
      200: '#c3dafe',
      300: '#a3bffa',
      400: '#7f9cf5',
      500: '#667eea',
      600: '#5a67d8',
      700: '#4c51bf',
      800: '#434190',
      900: '#3c366b',
    },
    valencia: {
      '50': '#fcf4f3',
      '100': '#f9e9e6',
      '200': '#f4d7d2',
      '300': '#ecb6ac',
      '400': '#e29183',
      '500': '#d76956',
      '550': '#d1543d',
      '600': '#ba412c',
      '700': '#8c3121',
      '800': '#782a1c',
      '900': '#672418',
    },
    yellow: {
      '50': '#ffffe8',
      '100': '#ffffb9',
      '200': '#ffff8b',
      '300': '#ffff5d',
      '400': '#ffff2e',
      '500': '#ffff00',
      '600': '#d1d100',
      '700': '#a2a200',
      '800': '#747400',
      '900': '#464600',
    },
    'fuchsia-pink': {
      '50': '#fbf3fa',
      '100': '#f8e8f5',
      '200': '#f1d5ec',
      '250': '#ecc5e5',
      '300': '#e6b2dc',
      '400': '#db8fcd',
      '450': '#d57cc4',
      '500': '#cc61b8',
      '600': '#b1399b',
      '650': '#9a3287',
      '700': '#872c76',
      '800': '#702462',
      '900': '#601f54',
      '1100': '#36112f',
    },
    'santa-fe': {
      '50': '#f9f4f1',
      '100': '#f4ebe6',
      '200': '#ead8cd',
      '300': '#dabca9',
      '400': '#c89b7e',
      '500': '#b47852',
      '600': '#8f5c3d',
      '650': '#815337',
      '700': '#6b452e',
      '800': '#5d3c28',
      '900': '#4b3120',
    },
    'roof-terracotta': {
      '50': '#fdf7f6',
      '100': '#fcf0ee',
      '200': '#f8e1dd',
      '300': '#f2c3ba',
      '400': '#ea9d90',
      '500': '#e27865',
      '600': '#d64329',
      '650': '#b43822',
      '700': '#a5331f',
      '800': '#892b1a',
      '900': '#782517',
    },
    'pigment-indigo': {
      '50': '#fbf5ff',
      '100': '#f6ebff',
      '200': '#f0dbff',
      '300': '#e1b8ff',
      '400': '#d294ff',
      '450': '#ca80ff',
      '500': '#c16bff',
      '550': '#b54dff',
      '600': '#aa33ff',
      '700': '#8300e0',
      '800': '#6e00bd',
      '900': '#5f00a3',
      '1000': '#4b0082',
    },
    'light-blue': {
      '50': '#f0f9ff',
      '100': '#e0f2fe',
      '200': '#bae6fd',
      '300': '#7dd3fc',
      '400': '#38bdf8',
      '500': '#0ea5e9',
      '600': '#0284c7',
      '700': '#0369a1',
      '800': '#075985',
      '900': '#0c4a6e',
    },
    'blue-gray': {
      '50': '#f8fafc',
      '100': '#f1f5f9',
      '200': '#e2e8f0',
      '300': '#cbd5e1',
      '400': '#94a3b8',
      '500': '#64748b',
      '600': '#475569',
      '700': '#334155',
      '800': '#1e293b',
      '900': '#0f172a',
    },
  },
};

export default brand;
