import { HttpClient } from 'data/protocols/http';

import { IResourceRepository } from 'domain/repositories/resource';
import { ApiResult } from './models/api-result';
import { getQueryParams, receiveResponse } from './utils';

export class ResourcesApi implements IResourceRepository {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ApiResult>) {}

  async createResource(params: any) {
    const httpResponse = await this.httpClient.request({
      // api/<resource>/
      url: `${this.url}`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }

  async getResource(id: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async getResources(page: number, filters: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/?${getQueryParams({ page, ...filters })}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async updateResource(id: any, values: any) {
    console.log('updateResource', id, values);
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'patch',
      body: values,
    });
    return receiveResponse(httpResponse);
  }

  async deleteResource(id: any) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    });
    return receiveResponse(httpResponse);
  }
}
