import { useCookies } from 'app/hooks/cookies';
import { AppRoutes } from 'app/pages/routes';
import { publicRoutes } from 'app/services/constants';
import { currentUserState } from 'app/state/atoms';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

interface SessionContextProps {
  user: any | undefined;
  isAuthenticated: boolean;
  canRefresh: boolean;
}

const SessionContext = React.createContext<SessionContextProps>({
  user: undefined,
  isAuthenticated: false,
  canRefresh: false,
});

export const SessionProvider = ({ children }: any) => {
  const history = useHistory();
  let location = useLocation();
  const { getCurrentUser } = useRecoilValue(currentUserState);

  const { getCookie } = useCookies();

  const currentProfile = getCurrentUser();
  const token = getCookie('session');
  const refreshToken = getCookie('refresh');

  const hasRefreshToken = !token && !!refreshToken;
  const hasNoToken = !token && !refreshToken;
  const isAuthenticated = !!currentProfile && !!token;

  useEffect(() => {
    if (hasNoToken && !publicRoutes.includes(location.pathname)) {
      history.push(AppRoutes.LOGIN);
    }
    // else do nothing

    // eslint-disable-next-line
  }, [location]);

  return (
    <SessionContext.Provider
      value={{
        user: currentProfile,
        isAuthenticated: isAuthenticated,
        canRefresh: !token && !currentProfile && !!hasRefreshToken,
      }}>
      {children}
    </SessionContext.Provider>
  );
};

export function useSession() {
  const context = React.useContext(SessionContext);
  return context;
}
