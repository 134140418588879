import { Box, Image, SimpleGrid, Text, useBreakpointValue } from '@chakra-ui/react';
import Page from 'app/components/page';
import { LButton } from 'app/components/widgets/buttons';
import {
  ElementImage,
  FiBrArrowLeftImage,
  FiBrArrowRightImage,
  FiBrDollar,
  FiBrUser,
  LeftLogoImage,
  RightLogoImage,
  TestimonialsImage,
  WorkDoneImage,
} from 'app/images';
import React, { useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { AppRoutes } from '../routes';
import PublicLayout from './layout';

export default function Home() {
  const btnSize = useBreakpointValue({ base: 'sm', md: 'md' });
  const mounted = useRef(false);
  // https://jasonwatmore.com/post/2021/08/27/react-how-to-check-if-a-component-is-mounted-or-unmounted
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <PublicLayout title="Home">
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css"
        />
      </Helmet>
      <Page>
        <Box>
          <section className="pt-10">
            <div className="container">
              <div className="flex items-center justify-center text-lg font-bold mt-[207px]">
                <div
                  className="col-md-6 wow animate__fadeInLeft animate__animated"
                  data-wow-duration="1s">
                  <h3 className="font-bold text-black text-xl md:text-3xl">
                    <span className="hidden md:block">LIMBO PAY - </span>TRANSACTIONS YOU CAN TRUST
                  </h3>
                  <p className="py-6">
                    Limbo Pay is your reliable payment platform whenever you hire, get help or
                    handover payments to someone. Signup Today!
                  </p>
                  <form className="flex space-x-4">
                    <Link to={`${AppRoutes.NEW_PAYMENT}/request`}>
                      <LButton btnSize={btnSize} dark>
                        Request Money
                      </LButton>
                    </Link>
                    <Link to={`${AppRoutes.NEW_PAYMENT}/purchase`}>
                      <LButton btnSize={btnSize}>Transfer Money</LButton>
                    </Link>
                  </form>
                </div>
                <div
                  className="hidden lg:block col-md-6 wow animate__fadeInRight animate__animated"
                  data-wow-duration="1s">
                  <img
                    src="./images/slider-element.png"
                    className="img-fluid ml-5"
                    alt="Responsive"
                  />
                </div>
              </div>
            </div>
          </section>

          <section className="mt-5">
            <div className="w-full text-black">
              <div className="flex text-center items-center justify-center">
                <div
                  className="col-sm-12 wow animate__fadeInLeft animate__animated"
                  data-wow-duration="1s">
                  <Text fontSize={['2xl', '3xl']} fontWeight="bold" color="black">
                    What Limbo Pay Does?
                  </Text>
                  <Text fontSize={['1rem', 'lg']}>
                    A platform for you to work with first timers and strangers without worrying
                    about your payments.
                  </Text>
                </div>
              </div>
            </div>
          </section>
        </Box>
        <section className="mt-10">
          <div className="container">
            <SimpleGrid
              columns={[1, 3]}
              gap={[8, 4]}
              style={{
                background: '#ffffff 0% 0% no-repeat',
                boxShadow: '0px 38px 42px #0000000d',
                borderRadius: '50px',
              }}
              px={['1.7rem', '5rem']}
              py={['1.5rem', '4rem']}
              className="w-full">
              <div className="wow animate__fadeInLeft animate__animated" data-wow-duration="1s">
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src={FiBrUser}
                    boxSize="70px"
                    className="img-fluid pb-3"
                    alt="Responsive"
                  />
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    Want to hire anyone?
                  </Text>
                  <Text className="text-center">
                    Signup, assign work to contractors and pay once you receive your work.
                  </Text>
                </div>
              </div>
              <div
                className="right-border wow animate__bounceInUp animate__animated"
                data-wow-duration="1s">
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src={FiBrDollar}
                    boxSize="70px"
                    className="img-fluid pb-3"
                    alt="Responsive"
                  />
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    100% secure payments
                  </Text>
                  <Text className="text-center">
                    Pay once your work is done. Your payments are cent percent secured.
                  </Text>
                </div>
              </div>
              <div
                className="right-border wow animate__fadeInRight animate__animated"
                data-wow-duration="1s">
                <div className="flex flex-col items-center justify-center">
                  <Image
                    src={WorkDoneImage}
                    boxSize="70px"
                    className="img-fluid pb-3"
                    alt="Responsive"
                  />
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    Pay after satisfaction
                  </Text>
                  <Text className="text-center">
                    Release payments once you are satisfied. No hidden charges, No extra
                    commitments.
                  </Text>
                </div>
              </div>
            </SimpleGrid>

            <div className="flex text-center mt-16">
              <div className="w-full">
                <div className="flex space-x-6 md:space-x-36 items-center justify-center">
                  <div className="left-col">
                    <Image boxSize={['40px', '70px']} src={LeftLogoImage} alt="Responsive" />
                  </div>
                  <div className="center-col limboanimation">
                    <Text fontSize="3xl" fontWeight="bold" color="black">
                      Why Limbo Pay?
                    </Text>
                    <p>Simple | Swift | Secure</p>
                  </div>
                  <div className="right-col">
                    <Image boxSize={['40px', '70px']} src={RightLogoImage} alt="Responsive" />
                  </div>
                </div>
              </div>
            </div>
            <SimpleGrid columns={[1, 3]} gap={[4, 0]} mt="8">
              <div className="flex items-start space-x-2" data-wow-duration="1s">
                <div className="flex flex-row-reverse md:flex-row w-16 md:w-auto">
                  <span className="text-7xl text-black font-bold">1</span>
                </div>
                <span>
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    Reliable and non-biased
                  </Text>
                  <p>Limbopay works on 100% non-partial behavior towards anybody.</p>
                </span>
              </div>
              <div className="flex items-start space-x-2" data-wow-duration="1s">
                <div className="flex flex-row-reverse md:flex-row w-12 md:w-auto">
                  <span className="text-7xl text-black font-bold">2</span>
                </div>
                <span>
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    Swift and secure Payments
                  </Text>
                  <p>Payments on the go. One click and you are done.</p>
                </span>
              </div>
              <div className="flex items-start space-x-2" data-wow-duration="1s">
                <div className="flex flex-row-reverse md:flex-row w-12 md:w-auto">
                  <span className="text-7xl text-black font-bold">3</span>
                </div>
                <span>
                  <Text fontSize="xl" fontWeight="bold" color="black">
                    Legal arbitration center for disputes
                  </Text>
                  <p>Disputes? Don't worry, Limbopay will manage it legally.</p>
                </span>
              </div>
            </SimpleGrid>
          </div>
        </section>

        <section className="mt-16 lg:my-24">
          <div className="container">
            <div
              className="col-sm-12 text-center wow animate__fadeInLeft animate__animated"
              data-wow-duration="1s">
              <Text fontSize="3xl" fontWeight="bold" color="black">
                Testimonials
              </Text>
              <Text fontSize="lg" fontWeight="semibold" color="gray.600">
                Your feedback matters, just like your payment's security.
              </Text>
            </div>
            <div className="flex flex-col md:flex-row w-full md:space-x-16 mt-8">
              <div className="w-3/5" data-wow-duration="1s">
                <Image
                  src={TestimonialsImage}
                  className="img-fluid element_image"
                  alt="Responsive"
                />
              </div>
              <div className="flex flex-col space-y-4" data-wow-duration="1s">
                <div className="flex space-x-2 w-full justify-end float-right">
                  <Image src={FiBrArrowLeftImage} className="img-fluid" alt="Responsive" />
                  <Image src={FiBrArrowRightImage} className="img-fluid" alt="Responsive" />
                </div>
                <div data-ride="carousel">
                  <div className="carousel-inner">
                    <div className="item active">
                      <div className="flex flex-col space-y-1">
                        <span>
                          <Image
                            boxSize={['30px', '70px']}
                            src={ElementImage}
                            className="float-left"
                            alt="Responsive"
                          />
                        </span>
                        <p className="text-black text-lg">
                          Amazing platform. I used it on my friend's recommendation and it was
                          great. I hired someone, paid it through limbopay after the work got done.
                          Its simple, its secure; its the limbo way! Try it now if you haven't
                        </p>
                        <span>
                          <Image
                            boxSize={['30px', '70px']}
                            src={ElementImage}
                            className="float-right"
                            alt="Responsive"
                          />
                        </span>
                      </div>
                      <div>
                        <Text fontSize="3xl" fontWeight="bold" color="black">
                          Michael
                        </Text>
                        <p className="font-semibold text-black text-lg">
                          Business Development Manager
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Page>
    </PublicLayout>
  );
}
