import create from 'zustand';
import api from 'app/services/api';
import { createUrlParams, getErrorMessage } from 'app/utils';

export const useCustomerStore = create<any>((set, get) => ({
  list: [],
  item: null,
  count: 0,
  error: null,
  photos: [],
  loading: false,
  invoices: [],
  profiles: [],
  addPhotos: (photo: any) => {
    const photos = get().photos;
    set({ photos: photos.concat(photo) });
  },
  savers: () => get().list?.filter((one: { savings: any; }) => Number(one.savings) > 980000),
  fetchOne: async (id: any) => {
    try {
      const url = `api/v1/customers/${id}`;
      const { data } = await api.get(url);
      set({ item: data.customer });
    } catch (error) {
      set({ error: error.message });
    }
  },
  fetchMany: async (filters: { per_page: number; }) => {
    set({ loading: true });
    try {
      filters.per_page = 10;
      const params = createUrlParams(filters);
      let url = 'api/v1/customers';
      if (params) url = `${url}?${params}`;
      const { data } = await api.get(url);
      set({ list: data.results });
      set({ count: data.total });
    } catch (error) {
      set({ error: error.message });
    }
    set({ loading: false });
  },
  search: async (term: any) => {
    set({ loading: true });
    try {
      const url = `api/v1/customers/search/${term}`;
      const { data } = await api.get(url);
      set({ list: data });
      set({ count: data.length });
    } catch (error) {
      set({ error: error.message });
    }
    set({ loading: false });
  },
  resetPin: async (id: any) => {
    set({ loading: true });
    try {
      const { data } = await api.put(`api/v1/customers/${id}?action=reset`, { action: 'reset' });
      set({ item: data.msg });
      set({ loading: false });
      return data;
    } catch (error) {
      set({ error: getErrorMessage(error) });
      set({ item: null });
      set({ loading: false });
    }
  },
  updateOne: async (id: any, values: any) => {
    set({ loading: true });
    try {
      const { data } = await api.put(`api/v1/customers/${id}`, values);
      set({ loading: false });
      return data;
    } catch (error) {
      set({ error: getErrorMessage(error) });
      set({ loading: false });
    }
  },
  setCount: (count: any) => set({ count }),
  setProfiles: (profiles: any) => set({ profiles }),
  getInvoices: async (id: any) => {
    set({ loading: true });
    try {
      const { data } = await api.get(`api/v1/invoices?customer_id=${id}`);
      console.log(data);
      set({ loading: false });
      set({ invoices: data?.results });
      return data?.results;
    } catch (error) {
      set({ error: error.message });
      set({ loading: false });
    }
  }
}));
