import { CheckIcon, CloseIcon, EditIcon } from '@chakra-ui/icons';
import {
  useEditableControls,
  EditableInput,
  ButtonGroup,
  Editable,
  EditablePreview,
  Flex,
  IconButton,
  Input,
  HStack,
} from '@chakra-ui/react';
import { Feature } from 'app/components/controls';
import { useResource } from 'app/hooks/backend/resource';
import React from 'react';

export default function EditableText({ attribute, value, endpoint, ...props }: any) {
  /* Here's a custom control */
  function EditableControls() {
    const { isEditing, getSubmitButtonProps, getCancelButtonProps, getEditButtonProps } =
      useEditableControls();

    return isEditing ? (
      <ButtonGroup justifyContent="center" size="sm">
        <IconButton aria-label="Submit" icon={<CheckIcon />} {...getSubmitButtonProps()} />
        <IconButton aria-label="Close" icon={<CloseIcon />} {...getCancelButtonProps()} />
      </ButtonGroup>
    ) : (
      <Flex justifyContent="center">
        <IconButton aria-label="Edit" size="sm" icon={<EditIcon />} {...getEditButtonProps()} />
      </Flex>
    );
  }

  const { updateResource } = useResource(endpoint);

  const handleEdit = (value: any) => {
    updateResource(props.id, { [attribute]: value });
  };

  return (
    <>
      <Feature
        permissions={{
          allowed: [{ attribute: 'is_superuser', value: false }],
          rejected: [],
        }}>
        {value}
      </Feature>
      <Feature
        permissions={{
          allowed: [{ attribute: 'is_superuser', value: true }],
          rejected: [],
        }}>
        <Editable defaultValue={value} isPreviewFocusable={false} onSubmit={handleEdit}>
          <HStack>
            <EditablePreview />
            <Input as={EditableInput} />
            <EditableControls />
          </HStack>
        </Editable>
      </Feature>
    </>
  );
}
