import { getCurrentUserAdapter, setCurrentUserAdapter } from "app/adapters";
import React from "react";
import { RecoilRoot } from "recoil";
import { currentUserState } from "app/state/atoms";


import { SessionProvider } from "app/contexts/session";
import PublicRoutes from "./public";
import UserAccountRoutes from "./user";
import { AdminRoutes } from "./admin";



const Router: React.FC = () => {
  const state = {
    setCurrentUser: setCurrentUserAdapter,
    getCurrentUser: getCurrentUserAdapter,
  };
  return (
    <RecoilRoot initializeState={({ set }) => set(currentUserState, state)}>
      <>
        <PublicRoutes />
        <SessionProvider>
          <UserAccountRoutes />
          <AdminRoutes />
        </SessionProvider>
      </>
    </RecoilRoot>
  );
};

export default Router;
