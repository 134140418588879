import { HttpClient } from 'data/protocols/http';
import { IAuthenticationRepository } from 'domain/repositories';
import { Environment } from 'env';
import { ApiResult } from './models/api-result';
import { receiveResponse } from './utils';

export class RemoteAuthentication implements IAuthenticationRepository {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ApiResult>) {}
  async login(
    params: IAuthenticationRepository.LoginParams
  ): Promise<IAuthenticationRepository.LoginResult> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/login`,
      headers: {
        Authorization: `Token ${Environment.API_TOKEN}`,
      },
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export declare namespace RemoteAuthentication {
  export type Model = IAuthenticationRepository.Model;
}
