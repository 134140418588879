import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Image } from '@chakra-ui/react';
import { AppRoutes } from 'app/pages/routes';

import SliderElementImage from 'app/images/files/slider-element.png';
import FiBrUser from 'app/images/files/fi-br-user.svg';
import FiBrDollar from 'app/images/files/fi-br-dollar.svg';
import WorkDoneImage from 'app/images/files/work-done.svg';
import TestimonialsImage from 'app/images/files/testimonials-picture.png';
import LeftLogoImage from 'app/images/files/left-logo-part.svg';
import RightLogoImage from 'app/images/files/right-logo-part.svg';
import FiBrArrowLeftImage from 'app/images/files/fi-br-arrow-left.svg';
import FiBrArrowRightImage from 'app/images/files/fi-br-arrow-right.svg';
import ElementImage from 'app/images/files/element.svg';
import LSettingsImage from 'app/images/files/fi-br-settings.svg';
import LMoneyImage from 'app/images/files/fi-br-settings.svg';
import LPrevPaymentImage from 'app/images/files/previous-payment.svg';
import LimboPayImage from 'app/images/files/logo.svg';
import BackwardsIcon from 'app/images/files/back-button.svg';
import LimboHalfLogo from 'app/images/files/reach-us.PNG';
import VisaLogo from 'app/images/files/visa.svg';
import MasterCardLogo from 'app/images/files/master-card.svg';
import AirtelLogo from 'app/images/files/airtel.svg';
import MTNLogo from 'app/images/files/mtn.svg';

export const LimboPayLogo = ({ size = '100px' }) => (
  <Link to={AppRoutes.HOME}>
    <Image boxSize={size} objectFit="fill" src={LimboPayImage} alt="LimboPay" />
  </Link>
);

export const BackButton = ({ size = '100px' }: any) => {
  const { goBack } = useHistory();

  return (
    <Image boxSize={size} objectFit="fill" src={BackwardsIcon} alt="Backwards" className='cursor-pointer' onClick={goBack} />
  );
};

export {
  MTNLogo,
  VisaLogo,
  MasterCardLogo,
  AirtelLogo,
  LimboHalfLogo,
  SliderElementImage,
  FiBrUser,
  FiBrDollar,
  WorkDoneImage,
  TestimonialsImage,
  LeftLogoImage,
  RightLogoImage,
  FiBrArrowLeftImage,
  FiBrArrowRightImage,
  ElementImage,
  LSettingsImage,
  LMoneyImage,
  LPrevPaymentImage,
  BackwardsIcon,
};
