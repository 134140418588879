import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Button,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  useBreakpointValue,
  Stack,
} from '@chakra-ui/react';
import { useResource } from 'app/hooks/backend/resource';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import { getStatusColor, LBadge } from '../controls/badges';
import Pagination from '../controls/pagination';

import { PaymentDetails } from '../widgets/payment-detail';
import { Feature } from '../controls';
import { DateInfo, Tags } from '../widgets/misc';

const ViewRecordModal = ({ isOpen, onClose, payment }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <PaymentDetails payment={payment} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

export const EntriesTable = ({ filters }: any) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { getResources, loading } = useResource('entries');
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedRecord, setSelectedRecord] = useState(null);

  const { isLoading, data } = useQuery(
    ['entries', { page, filters }],
    () =>
      getResources({
        page,
        filters,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const openViewRecordModel = (item: any) => {
    setSelectedRecord(item);
    onOpen();
  };

  if (isLoading || loading) return <Loading />;

  const list = data?.results;
  const total = data?.count;

  return (
    <>
      <Box className="lg:hidden flex flex-col space-y-2 text-sm">
        {list?.map((item: any) => (
          <Box
            key={`mobile-${item.id}`}
            p={2}
            borderLeftColor={`${getStatusColor(item.status?.toLowerCase())}.500`}
            borderLeftWidth="4px"
            rounded="md"
            bg={`${getStatusColor(item.status?.toLowerCase())}.100`}
            onClick={() => openViewRecordModel(item)}>
            <Box className="flex justify-between border-b-0">
              <div className="flex flex-col max-w-1/2">
                <span className="font-black">
                  {dayjs(item.date_created).format('DD MMM, YYYY')}
                </span>
                <i className="font-semibold text-gray-600">{item.tran_type}</i>
              </div>
              <div className="flex flex-col">
                <span className="font-black text-right">
                  {Number(item.amount).toLocaleString()}
                </span>
                <i className="font-black text-gray-600 text-right text-sm flex flex-end">
                  {/* <ContractStatus status={item.status} /> */}
                  {item.phone}
                </i>
              </div>
            </Box>
            <Text mt="1" fontSize="0.75rem">
              {item.narration}
            </Text>
          </Box>
        ))}
        <span className="mb-4" />
      </Box>
      <Table hidden={isMobile} variant="simple">
        <Thead>
          <Tr>
            <Th>ID</Th>
            <Th>From</Th>
            <Th>To</Th>
            <Th>Amount</Th>
            <Th>Type</Th>
            <Th>Channel</Th>
            <Th>Transaction</Th>
          </Tr>
        </Thead>
        <Tbody>
          {list?.map((item: any) => (
            <Tr key={item.id}>
              <Td>{item.uuid}</Td>
              <Td>{item.from_account}</Td>
              <Td>
                <Stack spacing={1}>
                  <Text fontSize="sm">{item.to_account}</Text>
                  <Text fontSize="xs" color="gray.500">
                    {item.meta.category}
                  </Text>
                </Stack>
              </Td>
              <Td>{Number(item.amount).toLocaleString()}</Td>
              <Td>
                <LBadge>{item.tran_type}</LBadge>
              </Td>
              <Td>
                <Tags tags={item.tags} />
              </Td>
              <Td>{item.tran_id}</Td>
              <Td>
                <DateInfo date={item.date_created} />
              </Td>
              <Feature>
                <Button onClick={() => openViewRecordModel(item)} size="xs" colorScheme="blue-gray">
                  View
                </Button>
              </Feature>
            </Tr>
          ))}
        </Tbody>
      </Table>
      <Pagination page={page} total={total} limit={10} setPage={setPage} />
      <ViewRecordModal isOpen={isOpen} onClose={onClose} payment={selectedRecord} />
    </>
  );
};
