import { IDomainResponse } from '.';
import { ArbitrationRepository, RaiseArbitration } from 'domain/repositories/arbitration';
export class ArbitrationsInteractor {
  constructor(private readonly repo: ArbitrationRepository) {}
  //get user arbitrations
  async getArbitrations(): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getArbitrations();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //get all arbitrations by admin
  async getAllArbitrations(): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getAllArbitrations();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //cancel user arbitration
  async cancelArbitration(params?: string): Promise<IDomainResponse> {
    try {
      const data = await this.repo.cancelArbitration(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //raise arbitration
  async raiseArbitration(params: RaiseArbitration): Promise<IDomainResponse> {
    try {
      const data = await this.repo.raiseArbitration(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
}
