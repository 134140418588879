import { SettingsIcon } from '@chakra-ui/icons';
import { Avatar, Box, Text, Link, Icon } from '@chakra-ui/react';
import { useSession } from 'app/hooks/backend/session';
import { LimboPayLogo } from 'app/images';
import { AppRoutes } from 'app/pages/routes';
import { adminMenus } from 'app/services/constants';
import { useStore } from 'app/state/store';
import classnames from 'classnames';
import React, { useEffect } from 'react';
import { FaList, FaMoneyBill } from 'react-icons/fa';
import { useLocation, Link as ReactLink } from 'react-router-dom';

export const SideBar = () => {
  const { setCurrentMenu } = useStore();
  const { user } = useSession();
  const location = useLocation();

  useEffect(() => {
    setCurrentMenu(adminMenus.find(menu => menu.key === location.pathname));
  }, [location.pathname, setCurrentMenu]);

  return (
    <aside className="z-20 flex-shrink-0 hidden w-60 overflow-y-auto bg-[#F3F6F9] md:block">
      <div className="flex flex-col items-start">
        <LimboPayLogo />
        <div className="flex space-x-2 items-center">
          <Avatar h={12} w={12} src={user?.profile_pic} />
          <div className="">
            <p className="font-semibold text-base text-gray-800 mt-2">
              {user?.first_name + ' ' + user?.last_name}
            </p>
            <Link
              as={ReactLink}
              to={AppRoutes.USER_PROFILE}
              className="text-light-blue-900 font-semibold text-sm">
              {user?.username}
            </Link>
          </div>
        </div>

        <div className="flex flex-col space-y-2 mt-12 w-full text-base">
          {adminMenus.map(menu => (
            <ReactLink
              to={menu.key}
              key={menu.key}
              className="flex justify-between items-center hover:text-limbo-primary">
              {location.pathname.startsWith(menu.key) ? (
                <div className="w-4 overflow-hidden inline-block">
                  <div className="h-6 w-4 bg-limbo-primary rotate-45 transform origin-top-left"></div>
                </div>
              ) : null}
              <span
                className={classnames(
                  'py-2 pl-2 w-full rounded cursor-pointer',
                  location.pathname.startsWith(menu.key) && 'font-bold text-white bg-limbo-primary'
                )}>
                {menu.name}
              </span>
            </ReactLink>
          ))}
        </div>

        <div className="mt-16"></div>
      </div>
    </aside>
  );
};

export const UserAccountMenu = () => {
  const { pathname } = useLocation();

  const menuOptions = [
    {
      name: 'New Payment',
      path: AppRoutes.NEW_PAYMENT,
      icon: <Icon as={FaMoneyBill} boxSize="1.2rem" />,
    },
    {
      name: 'Payments',
      path: AppRoutes.USER_PAYMENTS,
      icon: <Icon as={FaList} boxSize="1.2rem" />,
    },
    {
      name: 'Arbitrations',
      path: AppRoutes.USER_ARBITRATIONS,
      icon: <Icon as={FaList} boxSize="1.2rem" />,
    },
    { name: 'Profile', path: AppRoutes.USER_PROFILE, icon: <SettingsIcon boxSize="1.2rem" /> },
  ];

  return (
    <Box
      className="justify-end"
      p={['0.5', '4']}
      borderRadius={0}
      borderRight="2px solid transparent">
      <Text fontSize={['lg', 'xl']} fontWeight="bold" color="black">
        Select Option:
      </Text>
      <ul className="flex space-x-2 md:space-x-0 md:flex-col md:space-y-4 mt-4">
        {menuOptions.map(option => {
          const isCurrentPath = pathname.startsWith(option.path);

          return (
            <li key={option.path} className="flex items-start space-x-2">
              <span className="hidden md:block">{option.icon}</span>
              <Link
                as={ReactLink}
                to={option.path}
                pb="2"
                _hover={{
                  underline: 'none',
                  fontWeight: 'semibold',
                  borderBottom: '1px solid black',
                }}
                _focus={{
                  outline: 'none',
                }}
                opacity={isCurrentPath ? 1 : 0.5}
                borderBottom={isCurrentPath ? '2px solid black' : 'none'}>
                <Text fontSize={['0.7em', '0.95em']} fontWeight="semibold" color="black">
                  {option.name}
                </Text>
              </Link>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};
