import { getErrorMessage } from "app/utils";
import { HttpResponse, HttpStatusCode } from "data/protocols/http";
import { InvalidCredentialsError, UnexpectedError } from "domain/errors";
import { NotFoundError } from "domain/errors/not-found-error";

// eslint-disable-next-line
export const receiveResponse = (httpResponse: HttpResponse): any => {
  switch (httpResponse.statusCode) {
    case HttpStatusCode.ok:
      return httpResponse.body;
    case HttpStatusCode.created:
      return httpResponse.body;
    case HttpStatusCode.noContent:
      return httpResponse.body;
    case HttpStatusCode.badRequest:
      const msg = getErrorMessage(httpResponse.body);
      throw new UnexpectedError(msg);
    case HttpStatusCode.unauthorized:
      throw new InvalidCredentialsError();
    case HttpStatusCode.notFound:
      throw new NotFoundError();
    default:
      const error = getErrorMessage(httpResponse.body)
      throw new UnexpectedError(error);
  }
};

// eslint-disable-next-line
export const getQueryParams = (obj: any): string => {
  // input: {name: 'sam', age: 5, phone: '24565'}
  // output: ?name=sam&age=5&phone=24565
  const p = [];
  for (const key in obj) {
    if (obj[key]) p.push(key + "=" + encodeURIComponent(obj[key]));
  }
  return p.join("&");
};
