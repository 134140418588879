import { Button, VisuallyHidden } from "@chakra-ui/react";
import { useAuth } from "app/hooks/backend";
import React from "react";
import { FaSignOutAlt } from "react-icons/fa";

export default function LogoutButton(): JSX.Element {
  const { onLogout } = useAuth();
  return (
    <Button
      color="currentColor"
      variant="outline"
      borderRadius="full"
      focusBorderColor="black"
      _hover={{ bg: "black", color: "white" }}
      onClick={onLogout}>
      <VisuallyHidden>Logout</VisuallyHidden>
      <FaSignOutAlt />
    </Button>
  );
}
