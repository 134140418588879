import React from 'react';
import { Box, useColorModeValue as mode } from '@chakra-ui/react';

export const PageHeader = ({ children }: any) => (
  <Box className="relative flex flex-col min-w-0 break-words w-full mb-6">
    <div className="rounded-t mb-0 py-3 border-0">
      <div className="flex flex-wrap items-center">{children}</div>
    </div>
  </Box>
);

export default function Page({ children }: any) {
  return (
    <Box w={["100%", "75%"]} className="mx-auto" color={mode('gray.700', 'gray.400')}>
      <div className="flex flex-wrap mt-0 lg:mt-4">
        <div className="w-full mb-12 xl:mb-0">{children}</div>
      </div>
    </Box>
  );
}
