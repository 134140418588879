import { HttpClient } from 'data/protocols/http';

import {
  ChangePassword,
  GetUsersParams,
  IUserRepository,
  ResetPassword,
  SendCodeParams,
  UserProfile,
  VerifyCodeParams,
} from 'domain/repositories/user';
import { Environment } from 'env';
import { ApiResult } from './models/api-result';
import { getQueryParams, receiveResponse } from './utils';

export class UserApi implements IUserRepository {
  constructor(private readonly url: string, private readonly httpClient: HttpClient<ApiResult>) {}

  //get proflie of a certain user by username
  async getUserProfile(username: string): Promise<UserProfile | null> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${username}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async getUsers(params: GetUsersParams): Promise<any> {
    const queryParams = getQueryParams(params);
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/?${queryParams}`,
      method: 'get',
    });

    return receiveResponse(httpResponse);
  }
  //getting all Users
  async getAllUsers(): Promise<any> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  //get logeed in user
  async getProfile() {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/me`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }

  async createProfile(params: UserProfile) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }

  async updateProfile(params: UserProfile) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/me`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //change password
  async changePassword(params: ChangePassword) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/change-password`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //reset password
  async resetPassword(params: ResetPassword) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/start-password-reset`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //suspend user
  async suspendUser(username: string) {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/suspend`,
      method: 'post',
      body: { username },
    });
    return receiveResponse(httpResponse);
  }

  //send code
  async sendVerifyCode(channel: SendCodeParams) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/send-otp`,
      method: 'post',
      body: { channel: channel.channel },
    });
    return receiveResponse(httpResponse);
  }
  //verify code
  async verifyCode(params: VerifyCodeParams) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/verify-otp`,
      method: 'post',
      body: { otp: params },
    });
    return receiveResponse(httpResponse);
  }
}
