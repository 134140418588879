import { Box, Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import * as React from 'react';

interface Props extends FlexProps {
  label: string;
  value: string | undefined;
  direction?: 'row' | 'column';
}

export const Property = (props: Props) => {
  const { label, value, direction = 'row', ...flexProps } = props;
  return (
    <Flex
      as="dl"
      direction={{ base: 'column', sm: direction }}
      px="3"
      py="2"
      _even={{ bg: useColorModeValue('gray.50', 'gray.600') }}
      {...flexProps}>
      <Box as="dt" minWidth="180px" fontStyle="italic">
        {label}
      </Box>
      <Box as="dd" flex="1" fontWeight="semibold">
        {value}
      </Box>
    </Flex>
  );
};
