import { AppRoutes } from 'app/pages/routes';

export const webMenus = [
  { name: 'Home', path: AppRoutes.HOME },
  { name: 'About', path: AppRoutes.ABOUT },
  { name: 'Arbitration', path: AppRoutes.ARBITRATION },
  { name: 'Contact Us', path: AppRoutes.CONTACT },
];

export const adminMenus = [
  { name: 'Dashboard', key: AppRoutes.DASHBOARD },
  { name: 'Arbitrations', key: AppRoutes.ARBITRATIONS },
  { name: 'Payments', key: AppRoutes.PAYMENTS },
  // { name: 'Accounts', key: AppRoutes.ACCOUNTS },
  { name: 'Users', key: AppRoutes.USERS },
  { name: 'Tarriffs', key: AppRoutes.TARRIFFS },
];

export const publicRoutes = [
  AppRoutes.LOGIN,
  AppRoutes.SIGNUP,
  AppRoutes.CHANGE_PASSWORD,
  AppRoutes.HOME,
  AppRoutes.CONTACT,
  AppRoutes.ABOUT,
  AppRoutes.TERMS,
  AppRoutes.RESET_PASSWORD,
  AppRoutes.VERIFYRESTPASWORD,
  AppRoutes.RESET_PASSWORD_FORM,
];

export const ApiTasks = {
  INITIATE_PAYIN: 'initiate_payin',
  INITIATE_PAYOUT: 'initiate_payout',
  RETRY_PAYIN: 'retry_payin',
  RETRY_PAYOUT: 'retry_payout',
};
