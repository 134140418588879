import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect } from 'react';

import { TransactForm } from 'app/components/forms/transact';
import { Statistics } from 'app/components/widgets/statistics';
import { PaymentAdminTable } from 'app/components/tables/PaymentsAdminTable';
import useTransactionStore from 'app/state/store/transactions';
import useAuthStore from 'app/state/store/auth';
import { MakePaymentForm } from 'app/components/forms/MakePayment';

const TransactModel = ({ isOpen, onClose, onComplete }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Request User Payment</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <TransactForm onComplete={onComplete} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const MakePaymentModel = ({ isOpen, onClose, onComplete }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Transfer Payment to a User user</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <MakePaymentForm onComplete={onComplete} />
      </ModalBody>
    </ModalContent>
  </Modal>
);
const Contracts = () => {
  const {
    isOpen: isTransactModelOpen,
    onOpen: onTransactModelOpen,
    onClose: onTransactModelClose,
  } = useDisclosure();
  const {
    isOpen: isMakePaymentModelOpen,
    onOpen: onMakePaymentModelOpen,
    onClose: onMakePaymentModelClose,
  } = useDisclosure();
  const { getAllPayments, allTransactions, loading } = useTransactionStore();
  const { fetchAllUsers, users } = useAuthStore();

  useEffect(() => {
    const getData = async () => {
      await getAllPayments();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const getUsers = async () => {
      await fetchAllUsers();
    };
    if (users.length === 0) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Summary
            </Text>
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              All Payments
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="grid grid-cols-2 gap-4 md:w-1/3 divide-x divide-gray-50 my-8">
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-light-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-light-blue-500"
                onClick={onTransactModelOpen}>
                Request Payment
              </button>
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-light-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-light-blue-500"
                onClick={onMakePaymentModelOpen}>
                Make Payment
              </button>
              {/* <MakePaymentButton /> */}
            </div>
            <Statistics queryKey="contract_statistics" getStatistics={null} />
          </TabPanel>
          <TabPanel>
            <PaymentAdminTable allTransactions={allTransactions} loading={loading} />
          </TabPanel>
        </TabPanels>
      </Tabs>

      <TransactModel
        isOpen={isTransactModelOpen}
        onClose={onTransactModelClose}
        onComplete={() => {
          onTransactModelClose();
        }}
      />
      <MakePaymentModel
        isOpen={isMakePaymentModelOpen}
        onClose={onMakePaymentModelClose}
        onComplete={() => {
          onMakePaymentModelClose();
        }}
      />
    </Box>
  );
};

export default Contracts;
