import {
  Box,
  Divider,
  Link,
  SimpleGrid,
  Stack,
  Text,
  useColorModeValue as mode,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAuthRedirect } from 'app/hooks/backend';
import { LimboPayLogo } from 'app/images';
import AppleStore from 'app/images/appleStore.png';
import GooglePlay from 'app/images/googlePlay.png';
import { PublicNav } from 'app/layouts/navbar';
import { webMenus } from 'app/services/constants';
import dayjs from 'dayjs';
import React from 'react';
import { Helmet } from 'react-helmet';
import { Link as ReactLink } from 'react-router-dom';
import { AppRoutes } from '../routes';
import { ContactBanner } from './contact-banner';

type Props = {
  title?: string;
  children?: React.ReactNode;
  disableContactBanner?: boolean;
};

export default function PublicLayout({
  title,
  children,
  disableContactBanner = false,
}: Props): JSX.Element {
  const copyRightVariant = useBreakpointValue({
    base: 'LimboPay',
    md: 'LimboPay. All rights reserved',
  });

  useAuthRedirect();

  const limboGooglePalyLink =
    'https://play.google.com/store/apps/details?id=com.qriscorp.limbo_pay';

  const limboAppStoreLink = 'https://apps.apple.com/us/app/limbopay-app/id6444191728';

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title ? `${title} | ` : ''}LimboPay</title>
      </Helmet>
      <section>
        <PublicNav />
        <Box
          mt={{ base: '4', lg: '8' }}
          mx={[0, 'auto']}
          bg={mode('inherit', 'inherit')}
          color={mode('inherit', 'gray.800')}>
          <Box px={['4', '0']}>{children}</Box>
          {disableContactBanner ? null : <ContactBanner />}
        </Box>
        {/* Footer */}
        <Box
          w={['98%', '75%']}
          mx={[2, 'auto']}
          color={mode('gray.600', 'gray.400')}
          fontSize={['1.1rem', '0.9rem']}>
          <Divider my={['2', '8']} size="2" />
          <SimpleGrid
            columns={[1, 3]}
            gap={[5, 0]}
            px={['4', '0']}
            my={['4', '5']}
            className="flex items-start justify-between">
            <Box>
              <LimboPayLogo />
              <Stack direction="row" spacing={2}>
                {webMenus.map(menu => (
                  <Link key={menu.path} className="font-bold text-limbo-primary" to={menu.path}>
                    <span>{menu.name}</span>
                  </Link>
                ))}
              </Stack>
              <Text fontWeight="bold">
                Copyright © {dayjs().format('YYYY')} {copyRightVariant}
              </Text>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" color="black">
                Contact Us
              </Text>
              <Box>
                <Text fontSize="md" fontWeight="normal" color="black">
                  Plot 1645, Bukoto-Ntinda Road, <br /> Kampala, Uganda
                </Text>
                <Text fontSize="md" fontWeight="normal" color="black">
                  0326120251 | +256702059944
                </Text>
                <Text fontSize="md" fontWeight="normal" color="black">
                  info@limbopay.com
                </Text>
              </Box>
            </Box>
            <Box>
              <Text fontSize="2xl" fontWeight="bold" color="black">
                About LimboPay
              </Text>
              <Box mb="4">
                <Text fontSize="md" fontWeight="normal" color="black">
                  Limbo Pay is your reliable payment platform whenever you hire, get help or
                  handover payments to someone.
                </Text>
              </Box>
              <Link as={ReactLink} to={AppRoutes.TERMS} className="font-bold text-limbo-primary">
                <span>Terms and Conditions</span>
              </Link>
            </Box>
          </SimpleGrid>

          <div className="grid sm:flex mb-5 ml-5 sm:ml-0">
            {/* <div className="flex sm:grid">
              <Text fontSize="xl" fontWeight="bold" color="black" mr="2">
                DOWNLOAD NOW
              </Text>
            </div> */}

            <Box display="flex" alignItems="center">
              <a
                href={limboGooglePalyLink}
                target="_blank"
                rel="noopener noreferrer"
                className="text-limbo-primary w-32 sm:w-38 md:w-40">
                <img src={GooglePlay} alt="Google Play" />
              </a>
              <a
                href={limboAppStoreLink}
                target="_blank"
                rel="noopener noreferrer"
                className="ml-5 text-limbo-primary w-32 sm:w-38 md:w-40">
                <img src={AppleStore} alt="App Store" />
              </a>
            </Box>
          </div>
        </Box>
      </section>
    </>
  );
}
