import Page from 'app/components/page';
import PublicLayout from './layout';
import React from 'react';
import { Box, Divider, Grid, GridItem, Text } from '@chakra-ui/react';
import { ContactForm } from 'app/components/forms/contact';


export default function Contact() {
  return (
    <PublicLayout title="Contact Us">
      <Page>
        <Box>
          <iframe
            title="LimboPay Map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3989.7433291592924!2d32.60321037459108!3d0.35129086397237747!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x177dba32bf87c9c5%3A0xf4bbfc0987d850d8!2sQrisCorp%20(U)%20Limited!5e0!3m2!1sen!2sug!4v1712833530504!5m2!1sen!2sug"
            width="100%"
            height="350"
            style={{ border: 0 }}
            allowFullScreen={false}
            loading="lazy"></iframe>
        </Box>

        <Grid
          w={['100%']}
          templateColumns="repeat(5, 1fr)"
          gap={6}
          mx="auto"
          my={['2', '10']}
          alignItems="flex-start">
          <GridItem colSpan={[5, 3]}>
            <Box borderRadius="24px" className="bg-white p-8 border border-gray-200">
              <div className="container">
                <Text fontSize="2xl" fontWeight="bold" color="black">
                  Have Some Questions?
                </Text>
                <Text fontSize="md" mt={2} mb={6} fontWeight="bold" color="black">
                  We'd love to hear from you
                </Text>
                <ContactForm />
              </div>
            </Box>
          </GridItem>
          <GridItem colSpan={[5, 2]}>
            <Box borderRadius="24px" className="bg-white p-8 border border-gray-200">
              <div className="flex flex-col space-y-4">
                <div className="">
                  <Text fontSize="2xl" fontWeight="bold" color="black">
                    Contact Us
                  </Text>
                  <ul className="border border-gray-200 rounded-md p-4 mt-4">
                    <li className="flex space-x-4">
                      <img src="images/location.svg" alt="" />
                      <span>Plot 1645, Bukoto-Ntinda Road, <br /> Kampala, Uganda</span>
                    </li>
                    <Divider my="3" />
                    <li className="flex space-x-4">
                      <img src="images/phone-number.svg" alt="" />
                      <span>0326 120 251 | +256702059944</span>
                    </li>
                    <Divider my="3" />
                    <li className="flex space-x-4">
                      <img src="images/fi-br-envelope.svg" alt="" />
                      <span>info@limbopay.com</span>
                    </li>
                  </ul>
                </div>
                <div className="get-social">
                  <Text fontSize="lg" fontWeight="bold" color="black">
                    Get Social
                  </Text>
                  <ul className="flex space-x-2 mt-2">
                    <li className="list-inline-item">
                      <a
                        className="social-icon text-xs-center"
                        target="_blank"
                        rel="noreferrer"
                        href="http://facebook.com/limbopay">
                        <img src="images/facebook.svg" className="mr-1" alt="LimboPay" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-icon text-xs-center"
                        target="_blank"
                        rel="noreferrer"
                        href="http://twitter.com/limbopay">
                        <img src="images/twitter.svg" className="mr-1" alt="LimboPay" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-icon text-xs-center"
                        target="_blank"
                        rel="noreferrer"
                        href="http://instagram.com/limbopay">
                        <img src="images/instagram.svg" className="mr-1" alt="LimboPay" />
                      </a>
                    </li>
                    <li className="list-inline-item">
                      <a
                        className="social-icon text-xs-center"
                        target="_blank"
                        rel="noreferrer"
                        href="http://youtube.com/limbopay">
                        <img src="images/youtube.svg" className="mr-1" alt="LimboPay" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <img src="images/reach-us.png" className="mr-1" alt="Responsive" />
                </div>
              </div>
            </Box>
          </GridItem>
        </Grid>
      </Page>
    </PublicLayout>
  );
}
