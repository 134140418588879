import { Stack, Button } from '@chakra-ui/react';
import React from 'react';
import { useForm } from 'react-hook-form';
import { LButton } from '../widgets/buttons';
import { UserProfileForm } from './profile';
import { useFormErrors } from './utils';
import { useAuth } from 'app/hooks/backend';

export const UserForm = () => {
  const { onCreateUser, loading } = useAuth();
  const methods = useForm<any>();
  const { handleSubmit, errors } = methods;

  const onSubmit = handleSubmit(async data => {
    await onCreateUser({
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name,
      username: data.username,
      password: data.username,
    });
  });

  useFormErrors(errors);

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="8" mb="8">
        <UserProfileForm formMethods={methods} />
      </Stack>
      <Button dark type="submit" as={LButton} isLoading={loading}>
        Create User
      </Button>
    </form>
  );
};
