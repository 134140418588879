import classnames from 'classnames';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import { ChannelPieChart, Charts } from './charts';
import { StatCards } from './stat-cards';

export const Statistics = ({ getStatistics, queryKey }: any) => {
  const [currentStatus, setCurrentStatus] = useState('all');
  const { isLoading, data: statistics } = useQuery([queryKey], () => getStatistics(), {
    refetchOnWindowFocus: true,
  });

  const status_list = statistics?.details || [];
  const statuses = [...Object.keys(status_list)];

  return (
    <React.Suspense fallback={<Loading loading={isLoading} />}>
      <div className="flex space-x-4">
        {statuses.map((status: any) => (
          <span
            key={status}
            onClick={() => setCurrentStatus(status)}
            className={classnames(
              'text-sm text-blue-gray-600 cursor-pointer',
              currentStatus === status && 'font-black'
            )}>
            {status.toLowerCase()}
          </span>
        ))}
      </div>
      <StatCards status={currentStatus} statistics={statistics?.details[currentStatus]} />
      <div className="flex items-center space-x-2 divide-x divide-solid w-full mt-8">
        {/* https://apexcharts.com/react-chart-demos/box-whisker-charts/horizontal-boxplot/ */}
        <Charts className="w-1/2" />
        {statistics?.channels && (
          <ChannelPieChart
            className="w-1/2"
            data={statistics?.channels}
            currentStatus={currentStatus}
          />
        )}
      </div>
    </React.Suspense>
  );
};
