import { getChannelColor } from 'app/services/config';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { Feature } from '../controls';

export const ChannelPieChart = ({ data, className, currentStatus }: any) => {
  const channels = Object.keys(data).sort();
  const channel_values = channels?.map((channel: any) => data[channel][currentStatus]?.sum || 0);
  const channel_colors = channels.map((channel: any) => getChannelColor(channel));

  const state = {
    series: channel_values,
    options: {
      colors: channel_colors,
      chart: {
        width: 380,
        type: 'pie',
      },
      labels: channels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    },
  };

  return (
    <div className={className}>
      <ReactApexChart options={state.options} series={state.series} type="pie" width="100%" />
    </div>
  );
};

export const Charts = ({ className }: any) => {
  const options = {
    chart: {
      id: 'basic-bar',
    },
    xaxis: {
      categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999],
    },
  };
  const series = [
    {
      name: 'series-1',
      data: [30, 40, 45, 50, 49, 60, 70, 91],
    },
  ];
  return (
    <div className={className}>
      <Feature>
        <ReactApexChart options={options} series={series} type="line" width="100%" />
      </Feature>
    </div>
  );
};
