import * as React from 'react';
import { Box, HStack, Image, Link, useColorModeValue as mode } from '@chakra-ui/react';
import Config from 'app/services/config';
import { LimboPayLogo } from 'app/images';
import PublicLayout from 'app/pages/public/layout';
import { AppRoutes } from 'app/pages/routes';
import { useLocation, Link as ReactLink } from 'react-router-dom';
import { Feature } from 'app/components/controls';
import { useAuth } from 'app/hooks/backend';

type LayoutProps = {
  title: string;
  subtitle?: string;
  children: React.ReactNode;
};

const authMenus = {
  [AppRoutes.SIGNUP]: 'Signup',
  [AppRoutes.LOGIN]: 'Login',
  // [AppRoutes.RESET_PASSWORD]: 'Change Password',
};

export const AuthLayout = ({ title, children }: LayoutProps) => {
  const { pathname } = useLocation();
  const { tokens } = useAuth();

  return (
    <PublicLayout disableContactBanner>
      <Box className="lg:w-/5 xl:max-w-screen-sm mx-auto">
        <Feature on={!tokens}>
          <HStack spacing={3} mb="6">
            {Object.keys(authMenus).map(menu => (
              <Link
                as={ReactLink}
                key={menu}
                to={menu}
                fontSize={['xl', '2xl']}
                fontWeight="bold"
                borderBottom={menu === pathname ? '2px solid black' : 'none'}
                opacity={menu === pathname ? 1 : 0.5}
                pb="2"
                _focus={{ boxShadow: 'none', outline: 'none' }}
                _hover={{ underline: 'none' }}>
                {authMenus[menu]}
              </Link>
            ))}
          </HStack>
        </Feature>

        <Box
          mb={10}
          borderRadius="24px"
          className="py-4 px-8 md:p-12 lg:p-4 xl:py-8 xl:px-12 xl:max-w-2xl bg-white border">
          <h2
            className="text-center text-2xl text-black font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold mb-8">
            {title}
          </h2>
          <div>{children}</div>
        </Box>
      </Box>
    </PublicLayout>
  );
};

export const AuthLayoutOld = ({ title, children }: LayoutProps) => {
  return (
    <div className="lg:flex">
      <div className="lg:w-2/5 xl:max-w-screen-sm">
        <div className="ml-24">
          <LimboPayLogo size="80px" />
        </div>
        <div className="mt-4 px-12 sm:px-24 md:px-48 lg:px-12 lg:mt-8 xl:px-24 xl:max-w-2xl">
          <h2
            className={`text-center text-2xl text-black font-display font-semibold lg:text-left xl:text-2xl
                    xl:text-bold`}>
            {title}
          </h2>
          <div className="mt-8">{children}</div>
        </div>
      </div>
      <Box
        color={mode('gray.700', `${Config.THEME.primary}.200`)}
        bg="black"
        className={`hidden lg:flex items-center justify-center bg-${Config.THEME.primary}-100 flex-1 h-screen shadow-md`}>
        <div className="max-w-full transform duration-200 hover:scale-110 cursor-pointer">
          <Image
            boxSize="500px"
            objectFit="cover"
            src="./images/slider-element.png"
            width="100%"
            alt="LimboPay"
          />
        </div>
      </Box>
    </div>
  );
};

export function Copyright(): JSX.Element {
  return (
    <footer className="footer mt-auto footer-light">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 small">Copyright &copy; LimboPay</div>
          <div className="col-md-6 text-md-right small">
            <a href="#!">Privacy Policy</a>
            &middot;
            <a href="#!">Terms &amp; Conditions</a>
          </div>
        </div>
      </div>
    </footer>
  );
}
