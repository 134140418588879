/* eslint-disable @typescript-eslint/no-explicit-any */
import { ICache } from 'data/protocols/cache';
import cookie from 'js-cookie';

export class CookieStorageAdapter implements ICache {
  // https://github.com/js-cookie/js-cookie/wiki/Frequently-Asked-Questions#expire-cookies-in-less-than-a-day
  // 15 minutes new Date(new Date().getTime() + 15 * 60 * 1000);

  set(
    key: string,
    value: any,
    expiresIn?: any
  ): void {
    cookie.set(key, JSON.stringify(value), {
      expires: expiresIn,
      path: '/',
    });
  }

  remove(key: string): void {
    cookie.remove(key);
  }

  get(key: string): any {
    // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-2-0.html
    // https://stackoverflow.com/questions/54496398/typescript-type-string-undefined-is-not-assignable-to-type-string
    // You can now use the non-null assertion operator that is here exactly for your use case.
    // It tells TypeScript that even though something looks like it could be null, it can trust you that it's not:
    try {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return JSON.parse(cookie.get(key)!);
    } catch (error) {
      // this most likely is when the key value is empty
      return cookie.get(key);
    }
  }
}
