import {
    Box, Table, Thead, Tr, Th, Tbody, Td,
    Text
} from '@chakra-ui/react';
import { useResource } from 'app/hooks/backend/resource';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../controls/loading';
import Pagination from '../controls/pagination';

import { Copier, DateInfo } from '../widgets/misc';


export const AccountStatementTable = ({ isMobile, filters, accountId }: any) => {
    const [page, setPage] = useState(1);
    const { getResources, loading } = useResource(`accounts/${accountId}/statement`);

    const { isLoading, data } = useQuery(
        ['statement', { page, filters }],
        () => getResources({
            page,
            filters
        }),
        {
            refetchOnWindowFocus: false,
        }
    );

    if (isLoading || loading) return <Loading />;

    const list = data;
    const total = data?.length;

    return (
        <>
            <Box className="lg:hidden flex flex-col space-y-2 text-sm">
                {list?.map((item: any) =>
                    <Box
                        key={`mobile-${item.id}`}
                        p={2}
                        borderLeftColor="swamp-green.500"
                        borderLeftWidth="4px"
                        rounded="md"
                        bg="swamp-green.50">
                        <Box className="flex justify-between border-b-0">
                            <div className="flex flex-col max-w-1/2">
                                <span className="font-black">{dayjs(item.date_created).format("DD MMM, YYYY")}</span>
                                <i className="font-semibold text-gray-600">{item.tran_type}</i>
                            </div>
                            <div className="flex flex-col">
                                <span className="font-black text-right">{Number(item.amount).toLocaleString()}</span>
                                <i className="font-black text-gray-600 text-right text-sm flex flex-end">
                                    {item.phone}
                                </i>
                            </div>
                        </Box>
                        <Text mt="1" fontSize="0.75rem">{item.narration}</Text>
                    </Box>
                )}
                <span className="mb-4" />
            </Box>
            <Table hidden={isMobile} variant="simple">
                <Thead>
                    <Tr>
                        <Th>ID</Th>
                        <Th>Entry</Th>
                        <Th>Amount</Th>
                        <Th>Balance</Th>
                        <Th>Narration</Th>
                        <Th>Date</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {list?.map((item: any) =>
                        <Tr key={item.id}>
                            <Td>
                                <Copier value={item.entry_id}>{item.id}</Copier>
                            </Td>
                            <Td>{item.entry_id}</Td>
                            <Td>{Number(item.amount).toLocaleString()}</Td>
                            <Td>{Number(item.balance).toLocaleString()}</Td>
                            <Td>{item.narration}</Td>
                            <Td><DateInfo date={item.date_created} timeOff /></Td>
                        </Tr>
                    )}
                </Tbody>
            </Table>
            <Pagination page={page} total={total} limit={10} setPage={setPage} />
        </>
    )
}
