import { HttpClient } from 'data/protocols/http';

import {
  ApprovePaymentParams,
  CancelPaymentParams,
  CreatePayment,
  IPaymentRepository,
  MakePayment,
  PaymentCreateParams,
  Summary,
} from 'domain/repositories/payment';
import { ApiResult } from './models/api-result';
import { receiveResponse } from './utils';
import { Environment } from 'env';

export class PaymentsApi implements IPaymentRepository {
  // Define transactions and loading properties
  transactions: PaymentCreateParams[] = [];
  allTransactions: PaymentCreateParams[] = [];
  summary: Summary = {};
  loading: boolean = false;

  constructor(private readonly url: string, private readonly httpClient: HttpClient<ApiResult>) {}

  async requestPayment(params: CreatePayment) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/request_payment`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //make payment
  async makePayment(params: MakePayment) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/make_payment`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //approve payment
  async approvePayment(params: ApprovePaymentParams) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/approve_payment`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }

  //cancel payment
  async cancelPayment(params: CancelPaymentParams) {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/cancel_payment`,
      method: 'post',
      body: params,
    });
    return receiveResponse(httpResponse);
  }
  //get user payments
  async getPayments() {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }
  //get summary
  async getSummary() {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/summary`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }
  //all payments
  async getAllPayments() {
    const httpResponse = await this.httpClient.request({
      url: `${Environment.API_URL}/all_transactions`,
      method: 'get',
    });
    return receiveResponse(httpResponse);
  }
}
