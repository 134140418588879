import { GetStorage } from 'data/protocols/cache';
import { HttpClient, HttpRequest, HttpResponse } from 'data/protocols/http';
import { Environment } from 'env';

export class AuthorizeHttpClientDecorator implements HttpClient {
  constructor(private readonly getStorage: GetStorage, private readonly httpClient: HttpClient) {}

  async request(data: HttpRequest): Promise<HttpResponse> {
    let token = this.getStorage.get('session');
    const appToken = Environment.API_TOKEN;
    if (token === undefined) {
      token = appToken;
    }
    Object.assign(data, {
      headers: Object.assign(data.headers || {}, {
        Authorization: `Bearer ${token}`,
      }),
    });

    const httpResponse = await this.httpClient.request(data);
    return httpResponse;
  }
}
