import React from "react";
import { RouteProps, Route, Redirect } from "react-router-dom";

import MainLayout from "app/layouts";
import { AppRoutes } from "../routes";
import { useSession } from "app/hooks/backend/session";


const PrivateRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const { isAuthenticated, user } = useSession();

  return isAuthenticated && user.is_staff ? (
    <MainLayout>
      <Route {...props} />
    </MainLayout>
  ) : (
    <Route {...props} component={() => <Redirect to={isAuthenticated ? AppRoutes.HOME : AppRoutes.LOGIN} />} />
  );
};

export const ProtectedRoute: React.FC<RouteProps> = (props: RouteProps) => {
  const { isAuthenticated } = useSession();

  return isAuthenticated ? (
    <>
      <Route {...props} />
    </>
  ) : (
    <Route {...props} component={() => <Redirect to={AppRoutes.LOGIN} />} />
  );
};

export default PrivateRoute;
