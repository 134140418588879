import Config from 'app/services/config';
import { makeCookieStorageAdapter } from 'factory/cache';

export const useCookies = () => {
  const cookies = makeCookieStorageAdapter();

  const setCookie = async (
    key: string,
    value: any,
    expiresInMinutes: number = Config.COOKIE_TIMEOUT_IN_MINUTES
  ): Promise<void> => {
    const expiresIn = new Date(new Date().getTime() + expiresInMinutes * 60 * 1000)
    cookies.set(key, value, expiresIn);
  };

  const getCookie = (key: string): any => {
    const result = cookies.get(key);
    return result;
  };

  const removeCookie = (key: string): void => cookies.remove(key);

  const removeCookies = (keys: string[]): void => keys.forEach(key => removeCookie(key));

  return {
    setCookie,
    getCookie,
    removeCookie,
    removeCookies
  };
};
