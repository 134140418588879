import React from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { useSession } from 'app/hooks/backend/session';
import { UsersTable } from 'app/components/tables/users';
import { LButton } from 'app/components/widgets/buttons';
import { UserForm } from 'app/components/forms/user';
import { Feature } from 'app/components/controls';
import useAuthStore from 'app/state/store/auth';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}
const UserEditModal = ({ isOpen, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="3xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>User Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody mb="6">
        <UserForm />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const UsersView = () => {
  const { user } = useSession();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { fetchAllUsers, users, loading } = useAuthStore();

  React.useEffect(() => {
    const getData = async () => {
      await fetchAllUsers();
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Users
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Feature
              permissions={{
                allowed: [{ obj: user, attribute: 'is_staff', value: true }],
                rejected: [],
              }}>
              <Box mb="6">
                <Button onClick={onOpen} btnSize="sm" dark as={LButton}>
                  New User
                </Button>
              </Box>
            </Feature>
            <UsersTable users={users} loading={loading} />
            <UserEditModal isOpen={isOpen} onClose={onClose} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default UsersView;
