import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import React from 'react';

interface LModelProps {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  title?: string;
  size?: string;
  disableClose?: boolean;
}

export const LModal = ({
  isOpen,
  onClose,
  children,
  title,
  disableClose = false,
  size = 'md',
}: LModelProps) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    size={size}
    closeOnOverlayClick={!disableClose}
    motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton hidden={disableClose} onClose={onClose} />
      <ModalBody>{children}</ModalBody>
    </ModalContent>
  </Modal>
);
