import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

interface RouterContextProps {
    to: string | undefined;
    from: string | undefined;
}

const RouterContext = React.createContext<RouterContextProps>({ to: undefined, from: undefined });

export const RouterProvider = ({ children }: any) => {
    const location = useLocation();
    const [route, setRoute] = useState({ //--> it can be replaced with useRef or localStorage
        to: location.pathname,
        from: location.pathname
    });

    useEffect(() => {
        setRoute((prev) => ({ to: location.pathname, from: prev.to }))
    }, [location]);

    return <RouterContext.Provider value={route}>
        {children}
    </RouterContext.Provider>
}

export function useRouteHistory() {
    const context = React.useContext(RouterContext);
    return context;
}
