/* eslint-disable @typescript-eslint/no-explicit-any */
export type HttpRequest = {
  url: string;
  method: HttpMethod;
  body?: any;
  headers?: any;
};

export interface HttpClient<R = any> {
  /* 
    An optional client property if you prefer using an instance.
    This can be really useful especially if you are binding 
    additional config and methods to the client instance
    e.g. interceptors in axios
  */
  client?: any;
  request: (data: HttpRequest) => Promise<HttpResponse<R>>;
}

export type HttpMethod = 'post' | 'get' | 'put' | 'delete' | 'patch' | 'options';

export enum HttpStatusCode {
  ok = 200,
  created = 201,
  noContent = 204,
  badRequest = 400,
  unauthorized = 401,
  forbidden = 403,
  conflict = 409,
  notFound = 404,
  serverError = 500,
}

export type HttpResponse<T = any> = {
  statusCode: HttpStatusCode;
  body?: T;
};
