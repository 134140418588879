import {
  ApprovePaymentParams,
  CancelPaymentParams,
  CreatePayment,
  IPaymentRepository,
  MakePayment,
} from 'domain/repositories/payment';
import { IDomainResponse } from '.';

export class PaymentsInteractor {
  constructor(private readonly repo: IPaymentRepository) {}
  //request payment
  async requestPayment(params: CreatePayment): Promise<IDomainResponse> {
    try {
      const data = await this.repo.requestPayment(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //transfer payment
  async makePayment(params: MakePayment): Promise<IDomainResponse> {
    try {
      const data = await this.repo.makePayment(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //approve payment
  async approvePayment(params: ApprovePaymentParams): Promise<IDomainResponse> {
    try {
      const data = await this.repo.approvePayment(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //cancel payment
  async cancelPayment(params: CancelPaymentParams): Promise<IDomainResponse> {
    try {
      const data = await this.repo.cancelPayment(params);
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //get user payments
  async getPayments(): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getPayments();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //get summary
  async getSummary(): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getSummary();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
  //get all payments
  async getAllPayments(): Promise<IDomainResponse> {
    try {
      const data = await this.repo.getAllPayments();
      return { data, error: null };
    } catch (error) {
      return { data: null, error };
    }
  }
}
