import React from 'react';
import { Center, HStack, Icon, Text } from '@chakra-ui/react';
import UserAccountLayout from '../layout';
import PurchaseForm from 'app/components/forms/contract/purchase';
import { BackButton } from 'app/images';
import { Link, useHistory, useParams } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';
import { CheckCircleIcon } from '@chakra-ui/icons';
import { Feature } from 'app/components/controls';
import { LButton } from 'app/components/widgets/buttons';
import { FaTimesCircle } from 'react-icons/fa';
import { InvoiceForm } from 'app/components/forms/contract/invoice';

export function PurchaseView() {
  // tx_ref=ref&transaction_id=30490&status=successful
  const params = useParams<any>();
  const history = useHistory();

  return (
    <UserAccountLayout title="New Contract" width={['full', '80%']}>
      <HStack spacing={2} alignItems="center" mb="4">
        <BackButton size="1.3em" />
        <Text fontSize={['lg', 'xl']} fontWeight="bold" color="black">
          Make Payment
        </Text>
      </HStack>
      <Feature on={!!params?.status}>
        <Center flexDirection="column">
          <Text fontSize={['xl', '2xl']} fontWeight="bold" color="black">
            {params?.status === 'successful' ? 'Payment Successful' : 'Payment Failed'}
          </Text>
          {params?.status === 'successful' ? (
            <CheckCircleIcon color="eucalyptus.500" boxSize="5em" my="6" />
          ) : (
            <Icon as={FaTimesCircle} color="red.500" boxSize="5em" my="6" />
          )}
          <Link to={AppRoutes.USER_PAYMENTS}>
            <LButton btnSize="sm" dark>
              View Payments
            </LButton>
          </Link>
        </Center>
      </Feature>
      <Feature on={!params?.status}>
        <PurchaseForm onComplete={() => history.push(AppRoutes.USER_PAYMENTS)} />
      </Feature>
    </UserAccountLayout>
  );
}

export function InvoiceView() {
  const history = useHistory();

  return (
    <UserAccountLayout title="New Contract" width={['full', '75%']}>
      <HStack spacing={2} alignItems="center" mb="4">
        <BackButton size="1.3em" />
        <Text fontSize={['xl', '1.3em']} fontWeight="bold" color="black">
          Request Payment
        </Text>
      </HStack>
      <InvoiceForm onComplete={() => history.push(AppRoutes.USER_PAYMENTS)} />
    </UserAccountLayout>
  );
}
