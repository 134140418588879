import create from 'zustand';

export const useStore = create<any>(set => ({
  currentMenu: null,
  latestContract: {},
  setLatestContract: async (data: any) => {
    set({ latestContract: data });
  },
  setCurrentMenu: async (data: any) => set({ currentMenu: data }),
}));

export const useFormStore = create<any>(set => ({
  form: {},
  setForm: async (data: any) => {
    set({ form: data });
  },
  resetForm: async () => {
    set({ form: null });
  },
}));
