import React, { useEffect, useState } from 'react';
import { Avatar, Box, chakra, Tooltip, useClipboard } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';
import classnames from 'classnames';
import dayjs from 'dayjs';
import useAuthStore from 'app/state/store/auth';
import { UserProfile } from 'domain/repositories';

var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

export const DateInfo = ({ date, timeOff = false }: any) => (
  <>
    <div className="text-sm leading-5 flex items-center space-x-2">
      <span>{dayjs(date).format('YYYY-MM-DD')}</span>
    </div>
    {timeOff ? null : (
      <div className="text-sm leading-5 text-gray-500">{dayjs(date).fromNow()}</div>
    )}
  </>
);

export const Copier = ({ children, value }: any) => {
  const { hasCopied, onCopy } = useClipboard(value || children);

  const copyValue = (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    onCopy();
  };

  return (
    <Tooltip
      bg="limbo.secondary"
      isOpen={hasCopied}
      label={<span className="font-semibold">Copied</span>}>
      <span onClick={copyValue}>
        {children}
        <sup className="cursor-pointer px-2">
          <CopyIcon boxSize="0.75em" />
        </sup>
      </span>
    </Tooltip>
  );
};

export const Tags = ({ tags }: any) => (
  <Box className="flex flex-wrap">
    {!!tags &&
      tags.map((tag: string) => (
        <chakra.span
          key={tag}
          fontSize="0.8rem"
          className={classnames(
            'rounded-full border border-black bg-white text-black px-2 mr-1 mb-1 focus:outline-none'
          )}>
          {tag}
        </chakra.span>
      ))}
  </Box>
);

export const LimboUser = ({ username }: any) => {
  const { getSingleUser } = useAuthStore();
  const [user, setUser] = useState<UserProfile | null>();

  useEffect(() => {
    const getUser = async () => {
      // const userProfile = await getUserProfile(username);
      const userProfile = getSingleUser(username);
      setUser(userProfile);
    };
    getUser();
  }, [getSingleUser, username]);

  return (
    <div className=" flex items-center gap-2">
      <Avatar src={user?.profile_pic} />
      <div className="">
        <div className="text-sm leading-5 flex items-center space-x-2">
          <span>{username}</span>
        </div>
        <div className="text-sm leading-5 text-gray-500">
          {user?.first_name} {user?.last_name}
        </div>
      </div>
    </div>
  );
};
