import { Button, FormControl, FormLabel, Input, Stack, HStack, Textarea } from '@chakra-ui/react';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { LButton } from '../widgets/buttons';
import { useFormErrors } from './utils';


interface FormData {
  firstName: string;
  lastName: string;
  email?: string;
  phone: string;
  subject: string;
  description: string;
}

export const ContactForm = ({ onComplete }: any) => {
  const { register, errors } = useForm<FormData>();

  useFormErrors(errors);

  return (
    <form name="contact" data-netlify="true" netlify-honeypot="bot-field">
      <p className="hidden">
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <Stack spacing="6">
        <HStack spacing="3">
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <Input variant='flushed' name="firstName" type="text" required ref={register} focusBorderColor="black" />
          </FormControl>
          <FormControl>
            <FormLabel>Last Name</FormLabel>
            <Input variant='flushed' name="lastName" type="text" required ref={register} focusBorderColor="black" />
          </FormControl>
        </HStack>
        <HStack spacing="3">
          <FormControl>
            <FormLabel>Phone Number</FormLabel>
            <Input variant='flushed' name="phone" type="number" required ref={register} focusBorderColor="black" />
          </FormControl>
          <FormControl>
            <FormLabel>Email Address</FormLabel>
            <Input variant='flushed' name="email" type="text" required ref={register} focusBorderColor="black" />
          </FormControl>
        </HStack>
        <FormControl>
          <FormLabel>Subject</FormLabel>
          <Input variant='flushed' name="subject" type="text" required ref={register} focusBorderColor="black" />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea variant='flushed' focusBorderColor="black" name="description" type="textarea" rows={6} required ref={register} />
        </FormControl>
        <Button dark type="submit" as={LButton}>Send</Button>
      </Stack>
    </form>
  );
};
