import React from 'react';
import { HStack, Text, Tooltip } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import UserAccountLayout from '../layout';
import useAuthStore from 'app/state/store/auth';

export default function NewContractView() {
  const { fetchAllUsers, users } = useAuthStore();
  React.useEffect(() => {
    const getUsers = async () => {
      await fetchAllUsers();
    };
    if (users.length === 0) {
      getUsers();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <UserAccountLayout title="New Contract" width={['full', '75%']}>
      <HStack spacing={2} alignItems="center" mb="4">
        <Text fontSize={['xl', '2xl']} fontWeight="bold" color="black">
          Create a new payment
        </Text>
        <Tooltip label=" Any payment to initiate or request">
          <QuestionIcon />
        </Tooltip>
      </HStack>
      <HStack spacing={2}>
        <Link to="/new-payment/request">
          <img
            src="images/requst_pay.PNG"
            className="cursor-pointer"
            id="img_req"
            alt="Request Payment"
          />
        </Link>
        <Link to="/new-payment/purchase">
          <img
            src="images/transfer_pay.PNG"
            className="cursor-pointer"
            id="img_transfer"
            alt="Make Payment"
          />
        </Link>
      </HStack>
    </UserAccountLayout>
  );
}
