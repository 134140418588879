import {
  Box, Button, FormControl, HStack,
  Input, Select, SimpleGrid,
} from '@chakra-ui/react';
import { useResource } from 'app/hooks/backend/resource';
import React from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LButton } from '../widgets/buttons';
import { Label } from './fields';
import { useFormErrors } from './utils';

interface ChargeDto {
  min_value: number;
  max_value: number;
  charge_type: "flat" | "percentage";
  amount: number;
}

interface FormData {
  name: string;
  tran_type: string;
  pay_type: string;
  channel: string;
  tags?: string[];
}

export const TarriffForm = ({ onComplete }: any) => {
  const methods = useForm<FormData>({
    defaultValues: {
      tran_type: "flat",
      pay_type: "mobilemoney"
    }
  });

  const { register, errors, handleSubmit, control } = methods;
  const { createResource } = useResource('tarriffs');

  useFormErrors(errors);

  const onSubmit = handleSubmit(async values => {
    await createResource(values);
    onComplete && await onComplete();
  });

  return (
    <FormProvider {...methods}>
      <form onSubmit={onSubmit}>
        <SimpleGrid gap="6" columns={[1, 2]}>
          <FormControl>
            <Label>Name</Label>
            <Input variant='outline' name="name" type="text" required ref={register} focusBorderColor="black" />
          </FormControl>
          <FormControl>
            <Label>Tran Type</Label>
            <Controller
              control={control}
              name="tran_type"
              rules={{ required: true }}
              render={({ onChange, onBlur, value, name }) => (
                <Select
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  onChange={onChange}
                  variant='outline'
                  focusBorderColor="black"
                  placeholder="Select the type...">
                  {["payin", "payout"].map((item: any) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              )} />
          </FormControl>
          <FormControl>
            <Label>Pay Type</Label>
            <Controller
              control={control}
              name="pay_type"
              defaultValue={"mobilemoney"}
              rules={{ required: true }}
              render={({ onChange, onBlur, value, name }) => (
                <Select
                  onBlur={onBlur}
                  name={name}
                  value={value}
                  onChange={onChange}
                  variant='outline'
                  focusBorderColor="black"
                  placeholder="Select the type...">
                  {["mobilemoney"].map((item: any) => (
                    <option key={item} value={item}>
                      {item}
                    </option>
                  ))}
                </Select>
              )} />
          </FormControl>
          <FormControl>
            <Label>Channel</Label>
            <Input variant='outline' name="channel" type="text" required ref={register} focusBorderColor="black" />
          </FormControl>
        </SimpleGrid>
        <Box my="6">
          <Button dark type="submit" as={LButton}>Submit</Button>
        </Box>
      </form>
    </FormProvider>
  );
};


export const ChargesForm = ({ tarriff, onComplete }: any) => {
  const { control, errors, handleSubmit, register, reset } = useForm<ChargeDto>();

  const { createResource } = useResource('charges');

  useFormErrors(errors);



  const onSubmit = handleSubmit(async values => {
    await createResource({ ...values, tarriff });
    reset({
      min_value: Number(values.max_value) + 1,
      max_value: Number(values.max_value) + Number(values.min_value),
    })
    onComplete && await onComplete();
  });

  return (
    <form onSubmit={onSubmit}>
      <HStack spacing="6">
        <FormControl>
          <Input
            placeholder="Minimum Amount"
            name="min_value"
            ref={register}
            variant='outline'
            type="number"
            required
            focusBorderColor="black" />
        </FormControl>
        <FormControl>
          <Input
            placeholder="Maximum Amount"
            name="max_value"
            ref={register}
            variant='outline'
            type="number"
            required
            focusBorderColor="black" />
        </FormControl>
        <FormControl>
          <Controller
            control={control}
            defaultValue="flat"
            name="charge_type"
            rules={{ required: true }}
            render={({ onChange, onBlur, value, name }) => (
              <Select
                onBlur={onBlur}
                name={name}
                value={value}
                onChange={onChange}
                variant='outline'
                focusBorderColor="black"
                placeholder="Select the type...">
                {["flat", "percentage"].map((item: any) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </Select>
            )} />
        </FormControl>
        <FormControl>
          <Input
            placeholder="Charge Value"
            name="amount"
            ref={register}
            variant='outline'
            type="number"
            step="0.01"
            required
            focusBorderColor="black" />
        </FormControl>
      </HStack>
      <Box my="6">
        <Button dark type="submit" btnSize="sm" as={LButton}>Add Charge</Button>
      </Box>
    </form>
  )
};

