import React from 'react';
import { NavBar } from './navbar';
import { Box, useColorModeValue as mode} from '@chakra-ui/react';
import { SideBar } from './sidebar';

type Props = {
  children?: React.ReactNode;
};

function AppLayout({ children }: Props): JSX.Element {
  return (
    <div className="bg-[#F3F6F9] w-screen h-full min-h-screen">
      <Box className="flex w-full overflow-y-auto" py={[2, 8]} px={[2, 16]} bg={mode('#F3F6F9', 'blue-gray.100')} color={mode('inherit', 'gray.700')}>
        <SideBar />
        <Box width="100%">
          <NavBar />
          <Box minHeight="870" className="bg-gray-50 mt-0 lg:rounded-3xl shadow shadow-xs flex-auto w-full">
            {/* <Header /> */}
            {children}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default function MainLayout({ children }: Props): JSX.Element {
  return <AppLayout>{children}</AppLayout>;
}

