import { Box, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from '@chakra-ui/react';
import React from 'react';

import { Feature } from 'app/components/controls';
import { ChargesForm } from 'app/components/forms/tarriff';
import { ChargeSplitsTable } from 'app/components/tables/charge-splits';
import { ChargesTable } from 'app/components/tables/charges';
import { useSession } from 'app/contexts/session';
import { useResource } from 'app/hooks/backend/resource';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

const ChargesView = () => {
  const { tarriff_id } = useParams<any>();
  const { user } = useSession();
  const { getResource } = useResource('tarriffs');

  const { data: tarriff } = useQuery(['tarriffs', tarriff_id], () => getResource(tarriff_id));

  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              {tarriff?.name}
            </Text>
          </Tab>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Charge Accounts
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <Box>
              <Feature
                permissions={{
                  allowed: [{ obj: user, attribute: 'is_superuser', value: true }],
                  rejected: [],
                }}>
                <ChargesForm tarriff={tarriff_id} onComplete={() => null} />
              </Feature>
              <Box mt={4} />
              <ChargesTable filters={{ tarriff: tarriff_id, per_page: 10 }} />
            </Box>
          </TabPanel>
          <TabPanel>
            <Box>
              <Feature
                permissions={{
                  allowed: [{ obj: user, attribute: 'is_superuser', value: true }],
                  rejected: [],
                }}>
                <ChargesForm tarriff={tarriff_id} onComplete={() => null} />
              </Feature>
              <Box mt={4} />
              <ChargeSplitsTable filters={{ tarriff: tarriff_id, per_page: 10 }} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ChargesView;
