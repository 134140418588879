import React from 'react';
import { Switch } from 'react-router';
import PrivateRoute from '../private-route/private-route';

import { AppRoutes } from '../routes';
import Accounts from './accounts';
import ChargesView from './charges';
import Contracts from './contracts';
import Dashboard from './dashboard';
import AccountStatement from './statement';
import TarriffsView from './tarriffs';
import Transactions from './transactions/index';
import UsersView from './users';

export const AdminRoutes = () => (
  <Switch>
    <PrivateRoute path={AppRoutes.DASHBOARD} exact component={Dashboard} />
    <PrivateRoute path={AppRoutes.PAYMENTS} exact component={Contracts} />
    <PrivateRoute path={AppRoutes.ARBITRATIONS} exact component={Transactions} />
    <PrivateRoute path={AppRoutes.ACCOUNTS} exact component={Accounts} />
    <PrivateRoute path={AppRoutes.USERS} exact component={UsersView} />
    <PrivateRoute path={AppRoutes.TARRIFFS} exact component={TarriffsView} />
    <PrivateRoute path={`${AppRoutes.TARRIFFS}/:tarriff_id`} exact component={ChargesView} />
    <PrivateRoute path={`${AppRoutes.ACCOUNTS}/:account_id`} exact component={AccountStatement} />
  </Switch>
);
