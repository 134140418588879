import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  HStack,
  Tooltip,
  SimpleGrid,
} from '@chakra-ui/react';
import React from 'react';
import { useForm, UseFormMethods } from 'react-hook-form';
import { useFormErrors } from './utils';
import { useProfiles } from 'app/hooks/backend/profile';
import { LButton } from '../widgets/buttons';
import { useSession } from 'app/hooks/backend/session';
import { getPhoneNumber } from 'app/utils/regex';
import { QuestionIcon } from '@chakra-ui/icons';
import { PhonenumberInput } from './fields/phone';

interface UserProfileFormData {
  formMethods: UseFormMethods<any>;
  profile?: any;
}

export const UpdateProfileForm = ({ onComplete }: any) => {
  const methods = useForm<any>();
  const { updateProfile, loading } = useProfiles();
  const { user: profile } = useSession();
  const { handleSubmit, errors } = methods;

  useFormErrors(errors);

  const onSubmit = handleSubmit(async values => {
    await updateProfile({
      id: profile.id,
      values: { ...values },
    });
    onComplete && (await onComplete());
  });

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing="6">
        <UserProfileForm formMethods={methods} profile={profile} />
        <Button dark type="submit" as={LButton} isLoading={loading}>
          Update Profile
        </Button>
      </Stack>
    </form>
  );
};

export const UserProfileForm = ({ profile, formMethods }: UserProfileFormData) => {
  const { errors, register, control } = formMethods;

  return (
    <>
      <HStack spacing="6">
        <FormControl>
          <FormLabel color="gray.700">First Name</FormLabel>
          <Input
            defaultValue={profile?.first_name}
            variant="outline"
            focusBorderColor="black"
            name="first_name"
            type="text"
            required
            ref={register}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Last Name</FormLabel>
          <Input
            defaultValue={profile?.last_name}
            variant="outline"
            focusBorderColor="black"
            name="last_name"
            type="text"
            required
            ref={register}
          />
        </FormControl>
      </HStack>
      <SimpleGrid gap="6" columns={[1, 2]}>
        <FormControl>
          <FormLabel>
            <span>Phone Number</span>
            <Tooltip label="The phone number is your username" aria-label="A tooltip">
              <QuestionIcon ml="2" />
            </Tooltip>
          </FormLabel>
          <PhonenumberInput
            defaultValue={getPhoneNumber(profile?.username)}
            disabled={!!profile?.username}
            name="username"
            control={control}
            focusBorderColor={errors?.username ? 'red.600' : 'black'}
          />
        </FormControl>
        <FormControl>
          <FormLabel>Email</FormLabel>
          <Input
            defaultValue={profile?.email}
            variant="outline"
            focusBorderColor="black"
            name="email"
            type="text"
            required
            ref={register}
          />
        </FormControl>
      </SimpleGrid>
    </>
  );
};
