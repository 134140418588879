import { makeCookieStorageAdapter } from 'factory/cache';
import { Session } from 'domain/models/user';
import Config from 'app/services/config';

export const setCurrentUserAdapter = (session: Session): void => {
  makeCookieStorageAdapter().set('user', session, Config.COOKIE_TIMEOUT_IN_MINUTES);
};

export const getCurrentUserAdapter = (): Session => {
  return makeCookieStorageAdapter().get('user');
};

