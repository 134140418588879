import Page from 'app/components/page';
import PublicLayout from './layout';
import React from 'react';
import { Box, Image, SimpleGrid } from '@chakra-ui/react';

export default function About() {
  return (
    <PublicLayout title="About Us">
      <Page>
        {/* <section className="pt-20 lg:pt-[120px] pb-10 lg:pb-20 bg-[#F3F4F6]">
          <div className="container">
            <div className="flex flex-wrap -mx-4">
              <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                <div className="bg-white rounded-lg overflow-hidden mb-10">
                  <img src="./images/who-we-are.png" alt="first" className="w-full" />
                  <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <h2
                        className="
                                font-semibold
                                text-dark text-xl
                                sm:text-[22px]
                                md:text-xl
                                lg:text-[22px]
                                xl:text-xl
                                2xl:text-[22px]
                                mb-4
                                block
                                hover:text-primary
                                ">
                        Who We Are
                      </h2>
                    </h3>
                    <p className="text-base text-body-color leading-relaxed mb-7">
                      Limbopay is the next generation, online platform for secure payments while
                      working with strangers and first timers.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                <div className="bg-white rounded-lg overflow-hidden mb-10 rounded-t-2xl">
                  <img src="./images/how-he-work.png" alt="second" className="w-full" />
                  <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <h2
                        className="
                                font-semibold
                                text-dark text-xl
                                sm:text-[22px]
                                md:text-xl
                                lg:text-[22px]
                                xl:text-xl
                                2xl:text-[22px]
                                mb-4
                                block
                                hover:text-primary
                                ">
                        How We Work
                      </h2>
                    </h3>
                    <p className="text-base text-body-color leading-relaxed mb-7">
                      We hold payments, until your job is done and you are satisfied. Once you give
                      us the green signal, the payments gets released.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-1/2 xl:w-1/3 px-4">
                <div className="bg-white rounded-lg overflow-hidden mb-10">
                  <img src="./images/future-limbopay.png" alt="third" className="w-full" />
                  <div className="p-8 sm:p-9 md:p-7 xl:p-9 text-center">
                    <h3>
                      <h2
                        className="
                                font-semibold
                                text-dark text-xl
                                sm:text-[22px]
                                md:text-xl
                                lg:text-[22px]
                                xl:text-xl
                                2xl:text-[22px]
                                mb-4
                                block
                                hover:text-primary
                                ">
                        Future of LimboPay
                      </h2>
                    </h3>
                    <p className="text-base text-body-color leading-relaxed mb-7">
                      In future, limbopay will add more exciting features and will launch its own
                      mobile app.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <SimpleGrid
          className="mb-8 py-8 px-4 lg:px-16 rounded-md shadow-xs"
          columns={[1, 3]}
          spacing="60px">
          <Box w="260px">
            <figure className="flex flex-col space-y-10 items-center justify-center">
              <Image src="./images/who-we-are.png" alt="" w="19rem" h="16rem" />
              <figcaption className="flex flex-col items-center justify-center">
                <h2 className="font-bold text-black text-2xl">Who We Are</h2>
                <p className="text-center mx-auto w-full">
                  Limbopay is the next generation, online platform for secure payments while working
                  with strangers and first timers.
                </p>
              </figcaption>
            </figure>
          </Box>
          <Box w="260px">
            <figure className="flex flex-col space-y-10 items-center justify-center">
              <Image src="./images/how-he-work.png" alt="" w="19rem" h="16rem" />
              <figcaption className="flex flex-col items-center justify-center">
                <h2 className="font-bold text-black text-2xl">How We Work</h2>
                <p className="text-center mx-auto w:full">
                  We hold payments, until your job is done and you are satisfied. Once you give us
                  the green signal, the payments gets released.
                </p>
              </figcaption>
            </figure>
          </Box>
          <Box w="260px">
            <figure className="flex flex-col space-y-10 items-center justify-center">
              <Image src="./images/future-limbopay.png" alt="" w="19rem" h="16rem" />
              <figcaption className="flex flex-col items-center justify-center">
                <h2 className="font-bold text-black text-2xl">Future of LimboPay</h2>
                <p className="text-center mx-auto w:full">
                  In future, limbopay will add more exciting features and will launch its own mobile
                  app.
                </p>
              </figcaption>
            </figure>
          </Box>
        </SimpleGrid>
        <Box
          w="full"
          style={{
            background: '£fafafa 0% 0% no-repeat padding-box',
            opacity: 1,
            padding: '70px',
          }}>
          <Image src="./images/banner-image.svg" alt="" w="full" />
        </Box>
      </Page>
    </PublicLayout>
  );
}
