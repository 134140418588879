import React, { useState } from 'react';
import { Box, Button, HStack, Text, Tooltip } from '@chakra-ui/react';
import { QuestionIcon } from '@chakra-ui/icons';
import { ContractsTable } from 'app/components/tables/contracts';
import { useProfiles } from 'app/hooks/backend';
import UserAccountLayout from './layout';
import { LButton } from 'app/components/widgets/buttons';

export default function TransactionsView() {
  const { profile } = useProfiles();
  const [filters, setFilters] = useState<any>({});

  const handleOnClick = () => {
    if (filters.hasOwnProperty('created_by')) {
      delete filters['created_by'];
      setFilters({ ...filters });
    } else {
      setFilters({ ...filters, created_by: profile.username });
    }
  };

  return (
    <UserAccountLayout title="Transactions">
      <HStack spacing={2} alignItems="center" mb="4">
        <Text fontSize={['lg', 'xl']} fontWeight="bold" color="black">
          All Payments
        </Text>
        <Tooltip label="View all Payments">
          <QuestionIcon />
        </Tooltip>
      </HStack>
      <Box mb="4" fontSize="xs">
        <Button
          dark={filters.hasOwnProperty('created_by')}
          as={LButton}
          onClick={handleOnClick}
          btnSize="xs">
          INITIATED BY YOU
        </Button>
      </Box>
      {/* TRANSACTION TABLE */}
      <ContractsTable filters={filters} />
    </UserAccountLayout>
  );
}
