import React from 'react';
import { Text } from '@chakra-ui/react';
import { UpdateProfileForm } from 'app/components/forms';
import UserAccountLayout from './layout';


export default function UserProfile() {

    return (
        <UserAccountLayout title="User Profile" width={["full", "75%"]}>
            <div className="container">
                <Text fontSize={['xl', '2xl']} mb="4" fontWeight="bold" color="black">Update your profile</Text>
                <UpdateProfileForm />
            </div>
        </UserAccountLayout>
    );
}
