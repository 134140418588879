const theme = {
  primary: 'blue-gray',
  link: { light: '#37958C', dark: 'light-blue.400' },
};

const Config = {
  FLAGSMITH_KEY: 'WS5Z7zBWdh3GtBKVUJ6ofR',
  THEME: theme,
  AIRTEL_COLOR: '#da251c',
  MTN_COLOR: '#FFC72C',
  COOKIE_TIMEOUT_IN_MINUTES: 30,
};

export const getChannelColor = (channel: string) => {
  return {
      "airtel": Config.AIRTEL_COLOR,
      "mtn": Config.MTN_COLOR
  }[channel];
}

export default Config;
