import React, { useEffect, useState } from 'react';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useBreakpointValue,
  Text,
  Tooltip,
} from '@chakra-ui/react';

import { MakePaymentButton } from 'app/components/widgets/buttons/rave-button';
import { WarningIcon } from '@chakra-ui/icons';
import { TransactForm } from 'app/components/forms/transact';
import useTransactionStore from 'app/state/store/transactions';
import { Loading } from 'app/components/controls/loading';
import { MakePaymentForm } from 'app/components/forms/MakePayment';

const TransactModel = ({ isOpen, onClose, onComplete }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Request Payment for a user</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <TransactForm onComplete={onComplete} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const MakePaymentModel = ({ isOpen, onClose, onComplete }: any) => (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" closeOnOverlayClick={false}>
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>Transfer Payment to a User user</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <MakePaymentForm onComplete={onComplete} />
      </ModalBody>
    </ModalContent>
  </Modal>
);

const Card = ({ children }: any) => (
  <div className="p-4 text-light-blue-900 bg-light-blue-100 rounded">{children}</div>
);

const Dashboard = () => {
  const {
    isOpen: isTransactModelOpen,
    onOpen: onTransactModelOpen,
    onClose: onTransactModelClose,
  } = useDisclosure();
  const {
    isOpen: isMakePaymentModelOpen,
    onOpen: onMakePaymentModelOpen,
    onClose: onMakePaymentModelClose,
  } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { summary, getSummary, loading } = useTransactionStore();

  useEffect(() => {
    const getData = async () => {
      await getSummary();
    };
    getData();
  }, []);
  if (loading) return <Loading />;
  return (
    <Box w="100%" py={[2, 4]} px={[0, 6]}>
      <Tabs variant="unstyled" isLazy>
        <TabList>
          <Tab
            _focus={{ boxShadow: 'none', outline: 'none' }}
            borderBottom="2px"
            borderBottomColor="transparent"
            _selected={{ borderBottomColor: 'limbo.secondary', color: 'limbo.secondary' }}>
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-3 h-3 md:w-5 md:h-5"
              viewBox="0 0 24 24">
              <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
            </svg>
            <Text fontSize={['0.85rem', 'md']} className="font-semibold ml-2">
              Dashboard
            </Text>
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel>
            <div className="grid grid-cols-2 gap-4 md:w-1/3 divide-x divide-gray-50 my-8">
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-light-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-light-blue-500"
                onClick={onTransactModelOpen}>
                Request Payment
              </button>
              <button
                className="inline-flex justify-center px-4 py-2 text-sm font-semibold text-blue-900 bg-blue-100 border border-transparent rounded-md hover:bg-light-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-light-blue-500"
                onClick={onMakePaymentModelOpen}>
                Make Payment
              </button>
              {/* <MakePaymentButton /> */}
              {/* <MakePaymentForm /> */}
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:w-full my-8">
              <Card>
                <div className="flex justify-between">
                  <p className="text-sm uppercase text-blue-gray-600">Payments</p>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={
                      <div className="text-sm text-semibold">
                        All payments made on both mobile app and web including all successful and
                        unsuccessfull
                      </div>
                    }
                    bg="blue-gray.200"
                    color="black">
                    <WarningIcon />
                  </Tooltip>
                </div>
                <span className="flex mt-4 space-x-2">
                  <p className="text-3xl">{summary?.total_transactions || 0}</p>
                  <sup className="text-xs">Total</sup>
                </span>
              </Card>

              <Card>
                <div className="flex justify-between">
                  <p className="text-sm uppercase text-blue-gray-600">Arbitrations</p>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={
                      <div className="text-sm text-semibold">
                        All arbitrations made on both mobile app and web including all cancelled and
                        ongoing
                      </div>
                    }
                    bg="blue-gray.200"
                    color="black">
                    <WarningIcon />
                  </Tooltip>
                </div>
                <span className="flex mt-4 space-x-2">
                  <p className="text-3xl">{summary?.total_arbitrations || 0}</p>
                  <sup className="text-xs">Total</sup>
                </span>
              </Card>

              <Card>
                <div className="flex justify-between">
                  <p className="text-sm uppercase text-blue-gray-600">Users</p>
                  <Tooltip
                    hasArrow
                    placement="top"
                    label={
                      <div className="text-sm text-semibold">This is number of all Limbo users</div>
                    }
                    bg="blue-gray.200"
                    color="black">
                    <WarningIcon />
                  </Tooltip>
                </div>
                <span className="flex mt-4 space-x-2">
                  <p className="text-3xl">{summary?.total_users || 0}</p>
                  <sup className="text-xs">Total</sup>
                </span>
              </Card>
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <TransactModel
        isOpen={isTransactModelOpen}
        onClose={onTransactModelClose}
        onComplete={() => {
          onTransactModelClose();
        }}
      />
      <MakePaymentModel
        isOpen={isMakePaymentModelOpen}
        onClose={onMakePaymentModelClose}
        onComplete={() => {
          onMakePaymentModelClose();
        }}
      />
    </Box>
  );
};

export default Dashboard;
