import { Box, Link, useColorModeValue as mode, VStack } from '@chakra-ui/react';
import React from 'react';
import { Link as ReactLink } from 'react-router-dom';

import { Feature } from 'app/components/controls';
import {
  ChangePasswordForm,
  ChooseEntityForm,
  LoginForm,
  ResetStartForm,
  SignUpForm,
  VerifyFormReset,
  ResetPasswordFormData,
} from 'app/components/forms';
import { useAuth } from 'app/hooks/backend';
import { AuthLayout } from 'app/layouts/auth';
import Config from 'app/services/config';
import { AppRoutes } from '../routes';
import PublicLayout from '../public/layout';
import { Text } from '@chakra-ui/react';
import Page from 'app/components/page';

export function Login(): JSX.Element {
  const { tokens } = useAuth();

  return (
    <AuthLayout title="Sign in to your account">
      <Feature on={!tokens}>
        <LoginForm />
        <Box
          className="mt-8 text-sm font-display font-semibold text-center"
          color={mode('black', 'gray.200')}>
          Don't have an account ?{' '}
          <Link
            as={ReactLink}
            to={AppRoutes.SIGNUP}
            color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
            Sign up
          </Link>
        </Box>
        {/* forgot password */}
        <Box
          className="mt-4 text-sm font-display font-semibold text-center"
          color={mode('black', 'gray.200')}>
          <Link
            as={ReactLink}
            to={AppRoutes.RESET_PASSWORD}
            color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
            Forgot Password?
          </Link>
        </Box>
      </Feature>
    </AuthLayout>
  );
}

export function Signup(): JSX.Element {
  return (
    <AuthLayout title="Create an account">
      <SignUpForm />
      <Box
        className="mt-8 text-sm font-display font-semibold text-center"
        color={mode('gray.700', 'gray.200')}>
        Have an account already ?{' '}
        <Link
          as={ReactLink}
          to={AppRoutes.LOGIN}
          color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </Box>
    </AuthLayout>
  );
}

export function ResetStart(): JSX.Element {
  return (
    <AuthLayout title="Reset your account">
      <ResetStartForm />
    </AuthLayout>
  );
}
export function VerifyFormResetPassword(): JSX.Element {
  return (
    <PublicLayout title="Verify your phone number">
      <Page>
        <Box
          bg="white"
          mx="auto"
          px="24"
          py="4"
          my="24"
          width="fit-content"
          border="1px solid gray.200"
          borderRadius="24px">
          <Text fontSize="xl" fontWeight="black" mb="8">
            Verify your phone number
          </Text>
          <VerifyFormReset />
        </Box>
      </Page>
    </PublicLayout>
  );
}

export function ChangePassword(): JSX.Element {
  const { onLogout } = useAuth();
  return (
    <AuthLayout title="Change your password">
      <ChangePasswordForm />
      <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
        <Link
          as={ReactLink}
          color={mode(Config.THEME.link.light, Config.THEME.link.dark)}
          onClick={onLogout}>
          Log Out
        </Link>
      </div>
    </AuthLayout>
  );
}

//reset password
export function ResetPassword(): JSX.Element {
  return (
    <AuthLayout title="Reset your your password">
      <ResetPasswordFormData />
    </AuthLayout>
  );
}

export function ChooseEntity(): JSX.Element {
  return (
    <AuthLayout title="Choose Entity">
      <ChooseEntityForm />
      {/* <div className="mt-8 text-sm font-display font-semibold text-gray-700 text-center">
        <Link as={ReactLink} to={AppRoutes.LOGIN} color={mode(Config.THEME.link.light, Config.THEME.link.dark)}>
          Log In
        </Link>
      </div> */}
    </AuthLayout>
  );
}
