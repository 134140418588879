import {
  Box,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Skeleton,
  useBreakpointValue,
  chakra,
  Tooltip,
  Icon,
  Text,
  Center,
} from '@chakra-ui/react';

import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { getStatusColor } from '../controls/badges';
import { ContractDetail } from '../widgets/contract-detail';
import Pagination from '../controls/pagination';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import classnames from 'classnames';
import { Feature } from '../controls';
import { DateInfo, LimboUser } from '../widgets/misc';
import { useLocation } from 'react-router-dom';
import { AppRoutes } from 'app/pages/routes';
import { useProfiles } from 'app/hooks/backend';
import { PaymentCreateParams } from 'domain/repositories/payment';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  contract: PaymentCreateParams;
};

const ViewContractModal = ({ isOpen, onClose, contract }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} size="4xl" motionPreset="slideInBottom">
    <ModalOverlay />
    <ModalContent>
      <ModalHeader>View Transaction Details</ModalHeader>
      <ModalCloseButton onClose={onClose} />
      <ModalBody>
        <ContractDetail contract={contract} />
      </ModalBody>
    </ModalContent>
  </Modal>
);
type Propss = {
  loading: boolean;
  allTransactions: PaymentCreateParams[];
};
export const PaymentAdminTable = ({ allTransactions, loading }: Propss) => {
  const location = useLocation();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [page, setPage] = useState(1);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [selectedContract, setSelectedContract] = useState<PaymentCreateParams | any>(null);

  const openViewContractModel = (contract: PaymentCreateParams) => {
    setSelectedContract(contract);
    onOpen();
  };

  let filteredTransactions = allTransactions;
  // Calculate pagination
  const limit = 10; // Number of transactions per page
  const startIndex = (page - 1) * limit;
  const endIndex = startIndex + limit;
  const paginatedTransactions = filteredTransactions.slice(startIndex, endIndex);

  // if (loading) return <Loading />;
  const total = filteredTransactions?.length || 0;
  if (total === 0) return <Center>{'No transactions for now. Make some transactions 🙂'}</Center>;
  return (
    <>
      <Feature on={isMobile}>
        <Skeleton isLoaded={!loading}>
          <Box className="lg:hidden flex flex-col space-y-2 text-sm">
            {paginatedTransactions?.map((item: PaymentCreateParams) => (
              <Box
                key={`mobile-${item.uuid}`}
                className={`rounded border-l-4 border-${getStatusColor(
                  item.status
                )}-500 bg-${getStatusColor(item.status)}-50`}
                onClick={() => openViewContractModel(item)}>
                <Box className="flex justify-between border-b-0 p-2">
                  <div className="flex flex-col max-w-1/2">
                    <span className="font-black">
                      {dayjs(item.date_created).format('DD MMM, YYYY')}
                    </span>
                    <i className="font-black text-gray-400">{item.phone}</i>
                  </div>
                  <div className="flex flex-col">
                    <span className="font-black text-right">
                      {Number(item?.amount).toLocaleString()}
                    </span>
                    <i className="font-black text-gray-400 text-right flex flex-end">
                      {item.charges / 100}%
                    </i>
                  </div>
                </Box>
              </Box>
            ))}

            <span className="mb-4" />
          </Box>
        </Skeleton>
      </Feature>

      <Feature on={!isMobile}>
        <Box overflowX="auto">
          <Skeleton isLoaded={!loading}>
            <Table variant="simple" mb="6" size="sm">
              <Thead>
                <Tr>
                  <Th></Th>
                  <Feature
                    permissions={{
                      allowed: [
                        { obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS },
                      ],
                      rejected: [],
                    }}>
                    <Th>Payer</Th>
                    <Th>Receiver</Th>
                  </Feature>
                  <Th>Amount</Th>
                  <Th>Status</Th>
                  <Th>Date</Th>
                </Tr>
              </Thead>
              <Tbody>
                {paginatedTransactions?.map((item: PaymentCreateParams) => (
                  <Tr key={item.uuid}>
                    <Td>
                      <Tooltip
                        label={
                          item.sender !== item?.receiver && item.kind === 'transfer'
                            ? 'Paying'
                            : 'Requesting'
                        }>
                        {item.sender !== item?.receiver ? (
                          <Icon
                            transform={'rotate(315deg)'}
                            color="white"
                            className={classnames('rounded-full bg-limbo-error')}
                            as={ArrowForwardIcon}
                          />
                        ) : (
                          <Icon
                            transform={'rotate(315deg)'}
                            color="white"
                            className={classnames('rounded-full bg-limbo-contrast')}
                            as={ArrowBackIcon}
                          />
                        )}
                      </Tooltip>
                    </Td>
                    <Feature
                      permissions={{
                        allowed: [
                          { obj: location, attribute: 'pathname', value: AppRoutes.PAYMENTS },
                        ],
                        rejected: [],
                      }}>
                      <Td>
                        <LimboUser username={item.sender} />
                      </Td>
                      <Td>
                        <LimboUser username={item.receiver} />
                      </Td>
                    </Feature>
                    <Td>{Number(item.amount).toLocaleString()}</Td>

                    <Td>
                      <Text width="5rem">
                        <DateInfo date={item.date_created} />
                      </Text>
                    </Td>
                    <Td>
                      <chakra.button
                        px="2"
                        py="0.5"
                        fontSize="xs"
                        bg="limbo.primary"
                        color="white"
                        rounded="full"
                        _focus={{ boxShadow: 'none', outline: 'none' }}
                        onClick={() => openViewContractModel(item)}
                        _hover={{ bg: 'limbo.contrast' }}>
                        View
                      </chakra.button>
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Skeleton>
        </Box>
      </Feature>

      <Pagination page={page} total={total} limit={limit} setPage={setPage} />
      <ViewContractModal isOpen={isOpen} onClose={onClose} contract={selectedContract} />
    </>
  );
};
