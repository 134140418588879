import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Stack,
  Collapse,
  Alert,
  AlertIcon,
  InputGroup,
  InputRightAddon,
  Flex,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useFormErrors } from '../utils';
import dayjs from 'dayjs';
import { LButton } from '../../widgets/buttons';
import { Feature } from '../../controls';
import { getPhoneNumber } from 'app/utils/regex';
import { PhonenumberInput } from '../fields/phone';
import useAuthStore from 'app/state/store/auth';
import _ from 'lodash';
import useTransactionStore from 'app/state/store/transactions';
import { CreatePayment } from 'domain/repositories/payment';
import { UserProfile } from 'domain/repositories';
import { handleError, handleSuccess } from 'app/hooks/backend';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

interface FormData {
  amount: number;
  narration: string;
  period: number | string;
}

const periods = [
  { key: '1_HOUR', name: '1 hour', rate: 0.1, hours: 1 },
  { key: '3_HOURS', name: '3 hours', rate: 0.3, hours: 3 },
  { key: '12_HOURS', name: '12 hours', rate: 1, hours: 12 },
  { key: '1_DAY', name: '1 day', rate: 2, hours: 24 },
  { key: '3_DAYS', name: '3 days', rate: 4, hours: 72 },
  { key: '1_WEEK', name: '1 week', rate: 6, hours: 168 },
  { key: '2_WEEKS', name: '2 weeks', rate: 8, hours: 336 },
  { key: '4_WEEKS', name: '4 weeks', rate: 10, hours: 672 },
  { key: '6_WEEKS', name: '6 weeks', rate: 15, hours: 1008 },
];

interface CreateContractFormProps {
  onComplete?: Function;
  initialContractType?: string;
}

export const InvoiceForm = ({ onComplete }: CreateContractFormProps) => {
  const [userPhone, setUserPhone] = useState<any>('');

  const { requestPayment, loading } = useTransactionStore();
  const [details, setDetails] = useState({
    rate: 0,
    interest: 0,
    next_date: new Date().toLocaleDateString(),
    hours: 0,
  });
  const {
    handleSubmit,
    register,
    errors,
    getValues,
    reset: resetForm,
    watch,
    control,
  } = useForm<FormData>();
  const periodHours = watch('period');

  useFormErrors(errors);

  const onSubmit = handleSubmit(async values => {
    const { amount, narration, period } = values;
    const data: CreatePayment = {
      narration: narration,
      amount: amount,
      period: period,
      payer_account: userPhone,
      platform: 'web',
    };

    const response = await requestPayment(data);
    if (response.data.status === 200) {
      handleSuccess('Congratulations Your Request was sent successfully');
      resetForm();
      onComplete && (await onComplete());
    } else {
      handleError(response.data.message || 'Request is unsuccessful Please try again later');
    }
  });

  const handleAmountChange = (e: { target: { value: string } }) => {
    const selectedPeriod = getValues('period');
    const period = periods.find(p => p.key === selectedPeriod);
    const amount = Number(e.target.value);

    if (period) {
      const interest = amount * (period.rate / 100);
      setDetails({ ...details, interest, rate: period.rate, hours: period.hours });
    }
  };

  const { getSingleUser } = useAuthStore();
  const [payer, setPayer] = useState<UserProfile | null>();
  useEffect(() => {
    const fetchUser = async () => {
      if (_.size(userPhone) >= 9) {
        const userPayerProfile = await getSingleUser(userPhone);
        setPayer(userPayerProfile);
      }
    };
    fetchUser();
    return () => {
      setPayer(null);
    };
  }, [userPhone, getSingleUser]);

  return (
    <>
      <form onSubmit={onSubmit}>
        <Stack spacing="6" mb="6">
          <FormControl>
            <FormLabel className="flex items-center space-x-2">
              <span>Payer</span>
            </FormLabel>
            <PhonenumberInput
              name="payer"
              control={control}
              onInput={(e: any) => setUserPhone(getPhoneNumber(e.target.value))}
            />
            <Collapse in={!!payer} animateOpacity>
              <Alert status="info" rounded="sm" fontSize="sm" p="2" mt="2">
                <AlertIcon boxSize="16px" mr="1.5" />
                {payer && payer.first_name + ' ' + payer.last_name}
              </Alert>
            </Collapse>
          </FormControl>
          <FormControl>
            <FormLabel>Purpose</FormLabel>
            <Input focusBorderColor="black" name="narration" type="text" required ref={register} />
          </FormControl>
          <HStack spacing="4">
            <FormControl>
              <FormLabel>Amount</FormLabel>
              <Input
                name="amount"
                type="number"
                onChange={handleAmountChange}
                focusBorderColor="black"
                required
                ref={register({
                  min: { value: 500, message: 'Amount cannot be less than 500' },
                  max: { value: 10000000, message: 'Amount cannot be greater than 10m' },
                })}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Period</FormLabel>
              <InputGroup>
                <Input
                  focusBorderColor="black"
                  name="period"
                  type="number"
                  required
                  ref={register}
                />
                <InputRightAddon children="Hours" />
              </InputGroup>
            </FormControl>
          </HStack>
          <Flex direction="row-reverse">
            <span className="text-xs">
              <p className="text-gray-500 mb-2">Due Date</p>
              <p className="font-black md:font-semibold">
                {periodHours
                  ? dayjs().add(Number(periodHours), 'hour').format('DD MMM, YYYY HH:mm')
                  : 'No date'}
              </p>
            </span>
          </Flex>

          <Feature on={false}>
            <Box className="flex flex-col mx-0">
              <p className="text-gray-500">Your total payment will be</p>
              <span className="flex my-8">
                <sup>UGX</sup>
                <h4 className="font-black text-xl md:text-4xl">
                  {(details.interest + Number(getValues('amount'))).toLocaleString()}
                </h4>
              </span>
              <div className="flex space-x-8 lg:space-x-16">
                <Feature on={false}>
                  <p className="text-gray-500 mb-2">Rate</p>
                  <p className="font-black text-lg md:font-semibold lg:text-2xl">{details.rate}%</p>
                </Feature>
                <span>
                  <p className="text-gray-500 mb-2">Due Date</p>
                  <p className="font-black text-lg md:font-semibold lg:text-2xl">
                    {dayjs().add(details.hours, 'hour').format('DD MMM, YYYY HH:mm')}
                  </p>
                </span>
              </div>
            </Box>
          </Feature>
          <Button type="submit" as={LButton} dark size="md" fontSize="md" isLoading={loading}>
            Submit
          </Button>
        </Stack>
      </form>
    </>
  );
};
